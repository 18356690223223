import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import PaginationComponent from "react-reactstrap-pagination";

import { Container } from 'reactstrap';
import FaqPublicContentElement from '../../Faq/FaqPublicContentElement';
import NavigationLink from '../NavigationLink';
import { FAQPAGESIZE } from '../../../constants';
import "bootstrap/dist/css/bootstrap.min.css";



const setSezioni = (faqs, sezioni) => faqs.map(x => {
  x.sezioneDesc = (sezioni.find(y => y.idSezione === x.idSezione) || {}).nome || '';
  return x;
});

const FaqPublicListComponent = ({listaFaq, navArray, idSezione, setPage, stato, sezioni}) => {
  return(
    <Container style={{marginTop: 10}}>
      <NavigationLink navArray={navArray} idSezione={idSezione} />
      { stato.results.value &&
          <>
              <FaqPublicContentElement faqs={listaFaq} />
              {listaFaq.length > 0 && <PaginationComponent           
                  totalItems={stato.results.value.data.totalElements ? stato.results.value.data.totalElements : 0 }
                  pageSize={FAQPAGESIZE}
                  onSelect={(selPage) => {setPage(selPage - 1)}}
                  maxPaginationNumbers={3}
                  activePage={stato.page + 1}/>}
          </>
      }

      {stato.response.data && 
          <>
            <FaqPublicContentElement faqs={setSezioni(stato.response.data.content, sezioni)} />
            {stato.response.data.totalElements > 0 && <PaginationComponent           
                totalItems={stato.response.data.totalElements}
                pageSize={FAQPAGESIZE}
                onSelect={(selPage) => {setPage(selPage - 1)}}
                maxPaginationNumbers={3}
                activePage={stato.page + 1}/>}
          </>
      }
    </Container>
  );
}

FaqPublicListComponent.propTypes = {
  listaFaq: PropTypes.array,
  navArray: PropTypes.array,
  idSezione: PropTypes.string,
  setPage: PropTypes.func,
  stato: PropTypes.object,
  sezioni: PropTypes.array
};

FaqPublicListComponent.defaultProps = {
  listaFaq: [],
  navArray: [],
  sezioni: []
};

export default withRouter(FaqPublicListComponent);