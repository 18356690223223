/**
 * Form row input
 *
 * @see https://github.com/shoaibkhan94/reactstrap-formik
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

const RowChangeSelect = ({
  field: {...fields},
  form: {touched, errors, setFieldValue},
  labelcols, 
  showuntouched, 
  label,
  checkBoxName,
  checkBoxValueAfterChange,
  ...props
}) =>
  <FormGroup row>
    <Label md={labelcols} for={fields.name} className="label-color">{label}</Label>
    <Col md={12 - labelcols}>
      <Input {...props} {...fields} invalid={(showuntouched || touched[fields.name]) && !!errors[fields.name]} 
            onChange={e => {
                setFieldValue(fields.name, e.target.value)
                setFieldValue(checkBoxName, checkBoxValueAfterChange);
            }}/>
      <FormFeedback tooltip>{errors[fields.name]}</FormFeedback>
    </Col>
  </FormGroup>

RowChangeSelect.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
  checkBoxName: PropTypes.string.isRequired,
  checkBoxValueAfterChange: PropTypes.bool.isRequired
 };

 RowChangeSelect.defaultProps = { 
  labelcols: 3,
  showuntouched: false
 };

export default RowChangeSelect;
