import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container } from 'reactstrap';
import api from '../../api/'
import Loader from '../Loader';
import FaqComponent from './FaqComponent';
import Footer from '../Footer';
import {  getSezioni, getSottosezioni } from '../../reducers';


const printFaq = async() => {
  let data = await api.printFaq();
}

class FaqContainer extends Component {
  componentDidMount() {
    const { getSezioni, getSottosezioni } = this.props;
    getSezioni();
    getSottosezioni();
  }



  render() {
    const { sezioni, sottosezioni } = this.props;
    return (
      <>
        <Container >
          {sezioni.fetching && sottosezioni.fetching && <Loader isLoading={sezioni.fetching || sottosezioni.fetching} />}
          {sezioni.fetched && sottosezioni.fetched && <FaqComponent sezioni={sezioni.data.sort((a, b) => b.idSezione - a.idSezione)} sottosezioni={sottosezioni.data} printFaq={printFaq}/>}
          {sezioni.errors && sezioni.errors.length > 0 && <p>{sezioni.errors}</p>}
          {sottosezioni.errors && sottosezioni.errors.length > 0 && <p>{sottosezioni.errors}</p>}
        </Container>
        <Footer />
      </>
    );
  }
}

FaqContainer.propTypes = {};

FaqContainer.defaultProps = {};

const mapStateToProps = ({ sezioni, sottosezioni }) => {
  return {
    sezioni,
    sottosezioni
  }
};

export default connect(mapStateToProps, { getSezioni, getSottosezioni })(FaqContainer);