import { Formik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../../api';
import { errors } from '../../form';
import SideNavComponent from '../../Sidenav/SideNavComponent';
import MailNuovoApplicativoComponent from './MailNuovoApplicativoComponent';

const { OB } = errors;

// valori iniziali del form
const initialValues = {
  nomeApplicativo:'',
  organizzazione: '',
  active: true
};

// schema di validazione
const formSchema = Yup.object().shape({
  nomeApplicativo: Yup.string().required(OB),
  organizzazione: Yup.string().required(OB),
});

const MailNuovoApplicativoContainer = () =>{ 

  const setUserPayload = (info) => {

    let model = {
      "applicationName": info.nomeApplicativo,
      "environment": 'prod',
      "organization": info.organizzazione,
      "activeFlag": info.active ? 1 : 0
    }
    return model;
  }
  
  return (    
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={ async (values, actions)  => {

          let payload = setUserPayload(values);
          try{
            let response = await api.createApplication(payload)
            actions.setStatus({
              application: response.data,
              success: true,
            })  
            actions.setSubmitting(false)
          } catch (er) {
            actions.setStatus({
              success: false,
              errore: "Applicativo non creato correttamente"
            })  
            actions.setSubmitting(false)
          }
          
        }
      }
      render={props => 
        <Row style={{margin:'0'}}>
          <Col xs="1" className="hidden-xs-down sidebar" >
            <SideNavComponent />
          </Col> 
          <Col style={{overflowX:'auto'}}>
            <Container fluid  >
              <MailNuovoApplicativoComponent {...props} /> 
            </Container>
          </Col>
        </Row> 
      }
    >
    </Formik>
  )
};

MailNuovoApplicativoContainer.propTypes = {};

MailNuovoApplicativoContainer.defaultProps = {};

export default MailNuovoApplicativoContainer;