import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import styles from  './FaqContentElement.module.css';
import Truncate from 'react-truncate';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons'
import withSizes from 'react-sizes';
import { FAQDETAIL } from '../../../navigation/routes';
import { MOBILE_SIZE } from '../../../constants';

const FaqContentElementComponent = ({faq, isMobile, goto}) => {

    const dataFmt = (date) => {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    const statoFormatter = (row) => {
        let status;
        //cancellato
        if (row.cancellato === 1 ) {
          status = {icon: 'trash', classname: 'text-danger' };
          return(<span><FontAwesomeIcon icon={status.icon} className={status.classname}/></span>);
        } else {
          //non pubblicato
          if (row.pubblicato === 0){
            return ( <span ><FontAwesomeIcon size="lg" icon={faPause} className='text-warning'/></span>);
          }
          //pubblicato
          if(row.pubblicato === 1){
            status = {icon: 'check-circle', classname: 'text-success' };
            return ( <span ><FontAwesomeIcon size="lg" icon={status.icon} className={status.classname}/></span>);
          }
        }
      }

    const dispatchFaq = (goto, faq) => {
        goto({pathname:FAQDETAIL, state:faq});
    }

    const borderFormatter= (faq, styles) => {     
        
        let status = styles.bordered_warning;

               
        if(faq.pubblicato === 1)
          status = styles.bordered_success;
        
        if (faq.pubblicato === 0)
        status = styles.bordered_warning;

        if (faq.cancellato === 1 ) 
          status = styles.bordered_danger;

        return(status);
    }

    return(      
            <div id="ticket-container" className={`${styles.containerkit} ${isMobile ?  borderFormatter(faq, styles): ''}`} 
                 onClick={() => { dispatchFaq(goto, faq)}}>
                <Container fluid>
                    <Row className={`${styles.ticket_data} justify-content-end`}>
                    <Col>
                        {dataFmt(faq.dataInserimento)}
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className={styles.ticket_title}>
                            #&nbsp;<span className="text-info"><b>{faq.idFaq}</b></span>
                            <span className={styles.ticket_dash}>&nbsp;&mdash;&nbsp;</span>
                            {faq.titolo.toUpperCase()}
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: !isMobile ? 9 : 12}} >
                            <div id="ticket-corpus" className={styles.ticket_corpus}>
                                <Truncate lines={2} ellipsis={<span>...</span>}> {faq.domanda} </Truncate>
                            </div>
                        </Col>
                        {!isMobile && <Col xs={{size:1,  offset:2}} className={`${styles.ticket_icon}`} align="left">
                            {statoFormatter(faq)}
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
    );
}

FaqContentElementComponent.propTypes = {
    faq: PropTypes.object,
    goto: PropTypes.func
};
  
FaqContentElementComponent.defaultProps = {
  faq: {},
};
  

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE,

})
  

export default withSizes(mapSizesToProps)(FaqContentElementComponent);