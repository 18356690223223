import { Field } from 'formik';
import React from 'react';
import { Button, Container, Form, Alert, Row, Col } from 'reactstrap';
import { RowInput, RowSelectInput, RowCheckInput } from '../form';
import styles from './ScimRegistrazione.module.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SCIMMANGER } from '../../navigation/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const ScimRegistrazioneComponent = ({
  values,
  touched,
  errors,
  e,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldError,
  isSubmitting,
  isValid,
  groupList
}) => 
  <Container >
    <Row>
      <Col>      
        <div className={styles.mainContainer}>
          <h4 className={styles.title}>Crea Utente</h4>
          Inserisci tutte le informazioni dell'utente e crea un nuovo profilo 
        </div>
      </Col>
      <Col> 
        <div className={styles.buttonContainer}>
          <Link to={SCIMMANGER}><Button color="warning" size="sm"><FontAwesomeIcon icon={faArrowLeft} /><span className="smallLabel">&nbsp;Indietro<small></small></span></Button></Link>
        </div> 
      </Col>
    </Row>
    <hr/>
    {status &&  !status.success_reg && 
      <Alert color="danger">
        {status.errore}<br/>
      </Alert>}
    {(!status ||  !status.success_reg) &&
      <Form onSubmit={handleSubmit} >
        <div>
          <div className={styles.content}>
            <Row>
              <Col>
                <Field type="text" label="Username" name="username" component={RowInput} />
              </Col>
              <Col sm={{ size: 4, offset: 2 }}>
                <Field label="Profilo attivo" name="active" component={RowCheckInput} />  
              </Col>
            </Row>
            <Field label="Gruppo" name="group" list={groupList} component={RowSelectInput} />  
            <Field type="text" label="Nome" name="firstName" component={RowInput} />
            <Field type="text" label="Cognome" name="lastName" component={RowInput} />
            <Field type="text" label="Email" name="email" component={RowInput} />
            <Field type="text" label="Phone" name="phone" placeholder="+39 ...." component={RowInput} />
            <Field type="password" label="Password" name="password" component={RowInput} />
            <Field type="password" label="Conferma Password" name="repeatPassword" component={RowInput} />              
          </div>
          <div className={styles.submit}>
            <Button color="success" type="submit" disabled={isSubmitting || !isValid}><FontAwesomeIcon icon={faUserPlus} />&nbsp;Crea<small></small></Button>
          </div>
        </div>
      </Form>
    }
    {status && status.reg_done && <Alert color="success">La registrazione è avvenuta con successo.</Alert>}
    
  </Container>

ScimRegistrazioneComponent.propTypes = {
  groupList: PropTypes.array
};

ScimRegistrazioneComponent.defaultProps = {};

export default ScimRegistrazioneComponent;