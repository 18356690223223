import React from 'react';
import PropTypes from 'prop-types';

import { Col, FormGroup, Input, Label , InputGroup, Button, InputGroupAddon,FormFeedback} from 'reactstrap';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const RowInput = ({
    field: {...fields},
    form: {touched, errors},
    labelcols, 
    showuntouched, 
    label, 
    index,
    insert,
    remove,
    ...props
  }) =>
    <FormGroup row>
      <Label md={labelcols} for={fields.name} className="label-color">{label}</Label>
      <Col md={12 - labelcols}>
      <InputGroup>
            <Input {...props} {...fields} invalid={(showuntouched || touched[fields.name]) && !!errors[fields.name]}/>
            <FormFeedback tooltip>{errors[fields.name]}</FormFeedback>
            <InputGroupAddon addonType="append">
                <Button color="danger" onClick={() => remove(index)}><FontAwesomeIcon icon={faMinus} /></Button>
                <Button color="success" onClick={() => insert(index + 1, '')}><FontAwesomeIcon icon={faPlus} /></Button>
            </InputGroupAddon>
        </InputGroup>
      </Col>
    </FormGroup>
  
  RowInput.propTypes = {
    field: PropTypes.object.isRequired, // da Formik: name
    form: PropTypes.object.isRequired,  // da Formik: touched, errors
    label: PropTypes.string.isRequired, // etichetta
    labelcols: PropTypes.number,        // num colonne per etichetta
    showuntouched: PropTypes.bool,       // mostra errore anche campo non touched (es. numerici)
    index: PropTypes.number,
    insert: PropTypes.func,
    remove: PropTypes.func
   };
  
   RowInput.defaultProps = { 
    labelcols: 3,
    showuntouched: false
   };
  

export default RowInput;

