import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Loader from '../Loader';
import AdminComponent from './AdminComponent';
import { fetchStart, getSezioni, getMessaggi, countMessages, getSottosezioni } from '../../reducers';
import { addSezioni } from '../../selectors';
import './AdminContainer.css';
import SideNavComponent from '../Sidenav/SideNavComponent';
import { AuthContext } from '../../authentication';

class AdminContainer extends Component {
  
  state = {
    Sezione: 99,
    interval: null,
    timeToRefresh: false,
    page: {},
    utente: this.context.utente
  };

  componentDidMount() {
    const { fetchStart, getSezioni, getSottosezioni, getMessaggi, countMessages, history } = this.props;
    fetchStart();
    getSezioni();
    getSottosezioni();
    getMessaggi(this.state.Sezione, history.location.pathname , this.state.page[this.state.Sezione], this.state.utente.idRuolo);
    countMessages(history.location.pathname, this.state.utente.idRuolo);

    //setta l'aggiornamento automatico della pagina
    try {
      let interval = setInterval(async () => {
        this.setState({timeToRefresh: true});
      }, 120000);
      this.setState({interval: interval})
    }
    catch(e){
      console.log(e);
    }
  }

  //nuove chiamate alle api per aggiornare la pagina
  componentDidUpdate(prevProps, prevState){
    if( this.state.timeToRefresh ){

      const { getMessaggi, countMessages, history } = this.props;
      getMessaggi(this.state.Sezione, history.location.pathname, this.state.page[this.state.Sezione], this.state.utente.idRuolo);
      countMessages(history.location.pathname, this.state.utente.idRuolo);
      this.setState({timeToRefresh: false});

    } else {

      if (this.state.Sezione !== prevState.Sezione ){
        const { getMessaggi, history } = this.props;
        getMessaggi(this.state.Sezione, history.location.pathname, this.state.page[this.state.Sezione], this.state.utente.idRuolo);
      }
      if (this.state.page !== prevState.page ){
        const { getMessaggi, history } = this.props;
        getMessaggi(this.state.Sezione, history.location.pathname, this.state.page[this.state.Sezione], this.state.utente.idRuolo);
      } 

    }
  
    
  }

  //cancello il timer quando il componente non è più in uso
  componentWillUnmount(){
    try {
      clearInterval(this.state.interval)
    }
    catch(e){
      console.log(e);
    }
  }

  toggleSezione = (idSezione) => {
    this.setState(({ Sezione: idSezione }));
  };

  togglePage = (pageSez, idSezione) => {
    this.setState(({ page: {[idSezione]: pageSez} }));
  };

  render(props) {
    const { context, sezioni, sottosezioni, messaggi, contatori } = this.props;
    return (
      
            <Row style={{margin:'0'}}>
            <Col xs="1" className="hidden-xs-down sidebar" >
              <SideNavComponent />
            </Col> 
            <Col>
              <Container fluid  style={{paddingLeft:'0', paddingRight:'0', marginTop:'2rem'}}>
                {context.fetching &&  sezioni.fetching && contatori.fetching && messaggi.fetching &&
                  <Loader isLoading={context.fetching || sezioni.fetching || messaggi.fetching || contatori.fetching } /> }
                {context.fetched && sezioni.fetched && contatori.fetched && <AdminComponent {...props} context={context.data} sezioni={sezioni.data} messaggi={messaggi.data} sottosezioni={sottosezioni.data} stato={this.state} setSezione={this.toggleSezione} setPage={this.togglePage} contatore={contatori.data} />}
                {context.errors && context.errors.length > 0 && <p>{context.errors}</p>}
                {sezioni.errors && sezioni.errors.length > 0 && <p>{sezioni.errors}</p>}
              </Container>  
            </Col>
          </Row>
          )
        }
      }
  

AdminContainer.propTypes = {};

AdminContainer.defaultProps = {};

const mapStateToProps = ({ context, sezioni, messaggi, contatori, sottosezioni }) => {
  return {
    context,
    sezioni: addSezioni(sezioni),
    messaggi,
    contatori,
    sottosezioni
    }
};

export default connect(mapStateToProps, { fetchStart, getSezioni, getSottosezioni, getMessaggi, countMessages })(withRouter(AdminContainer));

AdminContainer.contextType = AuthContext;
