import { faEye, faArrowLeft, faUserEdit, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Collapse, Container, Form, Row } from 'reactstrap';
import { SCIMCERCA } from '../../../navigation/routes';
import { RowChangeSelect, RowCheckInput, RowInput, RowSelectInput } from '../../form';
import ScimJwt from '../ScimJwt';
import styles from './ScimModifica.module.css';


const ScimModificaComponent = ({
  status,
  handleSubmit,
  isSubmitting,
  isValid,
  groupList,
  jwtError,
  jwtObj,
  setControllo,
  controllo,
  setIsRigeneraCodice

}) => {
  
  const [isOpen, toggle] = useState(false);

  return(
    <Container >
      <Row>
        <Col>      
          <div className={styles.mainContainer}>
            <h4 className={styles.title}>Modifica Utente</h4>
            Modifica le informazioni dell'utente selezionato 
          </div>
        </Col>
        <Col> 
          <div className={styles.buttonContainer}>
            <Link to={SCIMCERCA}><Button color="warning" size="sm"><FontAwesomeIcon icon={faArrowLeft} /><span className="smallLabel">&nbsp;Indietro<small></small></span></Button></Link>
            &nbsp;&nbsp;<Button color="secondary" size="sm" onClick={() => toggle(!isOpen)}><FontAwesomeIcon icon={faEye} /><span className="smallLabel">&nbsp;JWT<small></small></span></Button>
          </div> 
        </Col>
      </Row>
      
      <Collapse isOpen={isOpen} >
          <ScimJwt jwtError={jwtError} jwtObj={jwtObj} controllo={controllo} setControllo={setControllo}/>
      </Collapse>
      <hr/>
        <Form onSubmit={handleSubmit} >
          <div>
            <div className={styles.content}>
              <Row>
                <Col>
                  <Field type="text" label="Username" name="username" component={RowInput} />
                </Col>
                <Col sm={{ size: 4, offset: 2 }}>
                  <Field label="Profilo attivo" name="active" component={RowCheckInput} />  
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field type="text" label="Phone" name="phone" placeholder="+39 ...." component={RowChangeSelect} checkBoxName="phoneVerified" checkBoxValueAfterChange={false} />
                </Col>
                <Col sm={{ size: 4, offset: 2 }}>
                  <Field label="Telefono verificato" name="phoneVerified" component={RowCheckInput} />
                </Col>
              </Row>
              <Field label="Gruppo" name="group" list={groupList} component={RowSelectInput} />  
              <Field type="text" label="Nome" name="firstName" component={RowInput} />
              <Field type="text" label="Cognome" name="lastName" component={RowInput} />
              <Field type="text" label="Email" name="email" component={RowInput} />
              
            </div>
            <div className={styles.submit}>
              <Button color="success" disabled={isSubmitting || !isValid} onClick={() => {setIsRigeneraCodice(false); handleSubmit()}} ><FontAwesomeIcon icon={faUserEdit} />&nbsp;Modifica<small></small></Button>&nbsp;
              <Button color="danger" disabled={isSubmitting || !isValid} onClick={() => {setIsRigeneraCodice(true); handleSubmit()}}><FontAwesomeIcon icon={faQrcode} />&nbsp;Rigenera QR<small></small></Button>
            </div>
          </div>
        </Form>
      {status &&  !status.success && <><br/><Alert color="danger"> {status.errore} </Alert></>}
      {status && status.success && <><br/><Alert color="success">Modifiche completate correttamente</Alert></>}
      
    </Container>
  )
}

ScimModificaComponent.propTypes = {
  groupList: PropTypes.array,
  jwtError: PropTypes.string,
  jwtObj: PropTypes.object,
  controllo: PropTypes.bool,
  setControllo: PropTypes.func,
  setIsRigeneraCodice: PropTypes.func
};

ScimModificaComponent.defaultProps = {};

export default ScimModificaComponent;