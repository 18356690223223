import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';

import Loader from '../../Loader';
import FaqPublicListComponent from './FaqPublicListComponent';
import Footer from '../../Footer';
import { getPublicFaq, getSezioni, getSottosezioni } from '../../../reducers';
import api from '../../../api';


class FaqPublicListContainer extends Component {

  state = {
    page: 0,
    results: {},
    response: {},
    search: false,
    searchString: ''
  };

  async componentDidMount() {
    const { getPublicFaq, getSezioni, getSottosezioni, match , location} = this.props;
    if(location.state === undefined){
      const results = await getPublicFaq(match.params.idSezione, match.params.idSottosezione, this.state.page );
      this.setState(({results: results}));
    } else {
      const response = await api.searchPublicFaqs(location.state.searchString, this.state.page);
      this.setState(({search: true}));
      this.setState(({searchString: location.state.searchString}))
      this.setState(({response: response}));
    }
    getSezioni();
    getSottosezioni();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { getPublicFaq, match } = this.props;
      if( this.state.page !== prevState.page ){
        if(this.state.search){
          const response = await api.searchPublicFaqs( this.state.searchString, this.state.page);
          this.setState(({response: response}));
        } else {
          const results = await getPublicFaq(match.params.idSezione, match.params.idSottosezione, this.state.page );
          this.setState(({results: results}));
        }
      }
  }

  togglePage = (page) => {
    this.setState(({ page: page }));
  };

  render() {
    const { publicFaqs, sezioni, sottosezioni, match, location} = this.props;

    const setNavArray = (sezioni, sottosezioni) => {
      let navArray = ["Home"];

      const sezione = sezioni.filter(sezione => sezione.idSezione === Number(match.params.idSezione))[0];
      navArray = [...navArray, sezione.nome];

      if(match.params.idSottosezione === "all"){
        return navArray;
      } else {
        const sottosezione = sottosezioni.filter(sottosezione => sottosezione.idSottoSezione === Number(match.params.idSottosezione))[0];
        navArray = [...navArray, sottosezione.descrizione];
        return navArray;
      }    
    }

    return (
      <>
      {location.state === undefined ? 
      <Container >
        {publicFaqs.fetching && sezioni.fetching && sottosezioni.fetching && <Loader isLoading={publicFaqs.fetching || sezioni.fetching || sottosezioni.fetching} />}
        { sezioni.fetched  && sottosezioni.fetched  && <FaqPublicListComponent  listaFaq={publicFaqs.data} navArray={setNavArray(sezioni.data, sottosezioni.data)} idSezione={match.params.idSezione} setPage={this.togglePage} stato={this.state}/>} 
        {publicFaqs.errors && publicFaqs.errors.length > 0 && <p>{publicFaqs.errors}</p>}
        {sezioni.errors && sezioni.errors.length > 0 && <p>{sezioni.errors}</p>}
        {sottosezioni.errors && sottosezioni.errors.length > 0 && <p>{sottosezioni.errors}</p>}
      </Container> : 
      sezioni.fetched  && <FaqPublicListComponent setPage={this.togglePage} stato={this.state} sezioni={sezioni.data} navArray={["Home", "Ricerca Libera"]} />
      }
      <Footer />
      </>
    );
  }
}

FaqPublicListContainer.propTypes = {};

FaqPublicListContainer.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    sezioni: state.sezioni,
    publicFaqs: state.publicFaqs,
    sottosezioni: state.sottosezioni
  }
};

export default connect(mapStateToProps, { getPublicFaq, getSezioni, getSottosezioni })(withRouter(FaqPublicListContainer));