import { Formik } from 'formik';
import phone from 'phone';
import React, {useState, useEffect} from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../../api';
import { errors } from '../../form';
import SideNavComponent from '../../Sidenav/SideNavComponent';
import ScimModificaComponent from './ScimModificaComponent';
import { groups } from '../../../helpers';
import { withRouter } from 'react-router-dom';
import { TELEFONO_VERIFICATO } from '../../../constants';

const { OB, EMAIL } = errors;

// schema di validazione
const formSchema = Yup.object().shape({
  firstName: Yup.string().required(OB),
  lastName: Yup.string().required(OB),
  email: Yup.string().email(EMAIL).required(OB),
  username: Yup.string().required(OB),
  phone: Yup.string().test(
    'phone',
    'Il numero di telefono deve essere formattato nel seguente formato: +39 ....',
    val => {
      if (val) {
        return val.includes('+') && phone(val)[0]
      } else {
        return true
      }
    }),
  group: Yup.string()
    .test(
      'section', 'Seleziona un gruppo',
      val => val !== '99'
    ).required(OB),
});

const ScimModificaContainer = ({location}) =>{ 

  const setDisplayPhone = (flagphoneVeried) => {
    
    if(flagphoneVeried){
      return TELEFONO_VERIFICATO;
    } else {
      return "non verificato";
    } 
      
  }

  const checkPhoneValidation = (displayString) => {
    if(displayString === TELEFONO_VERIFICATO)
      return true;
    else
      return false;
  }

  const setUserPayload = (infoUtente) => {

    let model = {
      "active": infoUtente.active,
      "displayName": infoUtente.firstName + ' ' + infoUtente.lastName,
      "emails": [
        {
          "value": infoUtente.email
        }
      ],
      "name": {
        "familyName": infoUtente.lastName,
        "formatted": infoUtente.firstName + ' ' + infoUtente.lastName,
        "givenName": infoUtente.firstName
      },
      "userName": infoUtente.username,
      "phoneNumbers": []
    }
    if(infoUtente.phone ){
      model.phoneNumbers = [
        {
          "display": startUserinfo.phoneNumbers === undefined ? 'Non verificato' : setDisplayPhone(infoUtente.phoneVerified),
          "type": startUserinfo.phoneNumbers === undefined ? 'work' : startUserinfo.phoneNumbers[0].type,
          "value": phone(infoUtente.phone)[0]
        }
      ]
    }
     
    return model;
  }


  const startUserinfo = location.state === undefined ? {} : location.state; //valori iniziali
  
  const initialValues = {
    group: startUserinfo.groups === undefined ? '' : startUserinfo.groups[0].value,
    firstName:  startUserinfo.name === undefined ? '' : startUserinfo.name.givenName,
    lastName:  startUserinfo.name === undefined ? '' : startUserinfo.name.familyName,
    username: startUserinfo.userName,
    phone:  startUserinfo.phoneNumbers === undefined ? '' : startUserinfo.phoneNumbers[0].value,
    email:  startUserinfo.emails === undefined ? '' :  startUserinfo.emails[0].value,
    active:  startUserinfo.active,
    phoneVerified: startUserinfo.phoneNumbers === undefined ? false : checkPhoneValidation(startUserinfo.phoneNumbers[0].display)
  };

  const [jwt, setjwt] = useState();
  const [jwtError, setjwtError] = useState();
  const [controllo, setControllo] = useState(true);
  const [isRegeneraCodice, setIsRigeneraCodice] = useState(false)

  useEffect(() => {
    const fetchData = async (username) => {
      await api.getValidJwt(username).then( response => {
        setjwt(response.data)})
      .catch ( error => {
        setjwtError("JWT valido non trovato, chiedere all'utente di effettuare l'accesso per ottenerne uno")
      });
    }
    fetchData(startUserinfo.userName)
  }, [controllo])


  return (    
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      isInitialValid = {true}
      onSubmit={ async (values, actions)  => {
          

          try{
            if(!isRegeneraCodice){
              let payload = setUserPayload(values);
              await api.changeLdapUserInfo(payload, values.group, startUserinfo.id );
            }else {
              await api.rigeneraCodice(values.email);
            }
            
            actions.setStatus({
              success: true
            })  
            actions.setSubmitting(false)
          } catch (er) {
            actions.setStatus({
              success: false,
              errore: !isRegeneraCodice ? "Modifica non effettuata" : "Codice non trovato"
            })  
            actions.setSubmitting(false)
          }
          
        }
      }
      render={props => 
        <Row style={{margin:'0'}}>
          <Col xs="1" className="hidden-xs-down sidebar" >
            <SideNavComponent />
          </Col> 
          <Col style={{overflowX:'auto'}}>
            <Container fluid  >
              <ScimModificaComponent {...props} groupList={groups} setIsRigeneraCodice={setIsRigeneraCodice} jwtObj={jwt} jwtError={jwtError} setControllo={setControllo} controllo={controllo} /> 
            </Container>
          </Col>
        </Row> 
      }
    >
    </Formik>
  )
};

ScimModificaContainer.propTypes = {};

ScimModificaContainer.defaultProps = {};

export default withRouter(ScimModificaContainer);