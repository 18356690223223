import React from "react";
import { Button, Card, CardImg, Col, Container, Row } from "reactstrap";
import creaUtente from "../../assets/crea_nuovo_utente.png";
import gestioneUtenti from "../../assets/modifica_utenti.jpg";
import SideNavComponent from "../Sidenav/SideNavComponent";
import styles from "./ScimManager.module.css";
import { Link } from "react-router-dom";
import { SCIMREGISTRAZIONE, SCIMCERCA } from "../../navigation/routes";

const ScimManagerContainer = () => {
  return (
    <Row style={{ margin: "0" }}>
      <Col xs="1" className="hidden-xs-down sidebar">
        <SideNavComponent />
      </Col>
      <Col style={{ overflowX: "auto" }}>
        <div className={styles.mainContainer}>
          <h4 className={styles.title}>Gestione Utenti LDAP</h4>
          <h6>
            in comunicazione con il{" "}
            <a
              href="https://auth-test.cbim.it"
              target="_blank"
              rel="noopener noreferrer"
            >
              server di autenticazione
            </a>
          </h6>
          <hr />
        </div>
        <Container fluid>
          <Row style={{ margin: "0" }}>
            <Col sm={{ size: 4, offset: 1 }}>
              <Card body>
                <CardImg
                  top
                  width="80%"
                  src={creaUtente}
                  alt="Crea nuovo utente"
                />
                {/* <Link to={SCIMREGISTRAZIONE}> */}
                <Button
                  href="https://eu-west-1.console.aws.amazon.com/cognito/home?region=eu-west-1#"
                  target="_blank"
                >
                  Crea nuovo utente
                </Button>
                {/* </Link> */}
              </Card>
            </Col>
            <Col sm={{ size: 4, offset: 2 }}>
              <Card body>
                <CardImg
                  top
                  width="80%"
                  src={gestioneUtenti}
                  alt="Crea nuovo utente"
                />
                <Link to={SCIMCERCA}>
                  <Button>Gestisci utenti esistenti</Button>
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default ScimManagerContainer;
