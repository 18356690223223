import React from 'react';
import PropTypes from 'prop-types';
import { Container, Card, CardHeader, CardFooter, CardBody, CardSubtitle, CardTitle, Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import api from '../../../api';
import { NONEVASI , FAQMANAGER} from '../../../navigation/routes';
import { filterUtente } from '../../../helpers';
import moment from 'moment';
import './EvasoComponent.css';
import withSizes from 'react-sizes'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { MOBILE_SIZE } from '../../../constants';

const cancella = (updatable, updateMessaggio, goto, status) => {
  
  confirmAlert({
    title: 'Sei sicuro di voler procedere?',
    message: 'Facendo click su "Conferma" procederai eliminerai il ticket #' + updatable.idMessaggio + '. Ne sei sicuro?',
    buttons: [
      {
        label: 'Conferma',
        onClick: () => {
          updatable.cancellato = status === 0 ? 1 : 0;
          updateMessaggio(updatable);
          goto(NONEVASI);
        }
      },
      {
        label: 'Annulla',
        onClick: () =>  {return false}
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  })
    
}

const downloadAllegato = (file) => {
  api.filedownload(file);
}

const EvasoComponent = ({ messaggio, utenti, updateMessaggio, goto, isMobile }) => {

  const dataFmt = (data) => moment(data).format('DD/MM/YYYY HH:mm');

  return (

    <Container style={{ marginTop: '3rem', textAlign: 'left' }}>
      <Card>

        <CardHeader color="info">
          <CardTitle><h3>Ticket &middot; <span style={{ color: ' #007bff ' }}><b>{messaggio.idMessaggio}</b></span></h3></CardTitle>
          <CardSubtitle><small>Ticket ricevuto in data: <b>{dataFmt(messaggio.dataRicezione)}</b></small></CardSubtitle>
        </CardHeader>

        <CardBody>
          <Container>
            <Row>
                <Col sm="4" className="labels">
                  <span className="labels-title">Mittente:</span>&nbsp;<span>{`${messaggio.nome} ${messaggio.cognome}`}</span>
                </Col>
                <Col sm="4" className="labels">
                  <span className="labels-title">Email:</span>&nbsp;<span>{messaggio.email}</span>
                </Col>
                <Col sm="4" className="labels">
                  <span className="labels-title" >Telefono:</span>&nbsp;<span>{messaggio.telefono}</span>
                </Col>
            </Row>
            <Row>
              <Col sm="5">
                <Row className="labels"><span className="labels-title">Messaggio:<br/>&nbsp;</span></Row>
                <Row className="readable-text" style={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: '4px', padding: '1rem' }}>{messaggio.messaggio}</Row>
              </Col>
              {!isMobile && <Col sm="2"></Col>}
              <Col sm="5">
                <Row className="labels">
                  <div className="labels-title">Risposta:<br/>
                      <span className="text-muted">
                      <small>
                      <b>{filterUtente(utenti, messaggio.risposte[0].idUtente)}&nbsp;</b> 
                      in data: <b>{dataFmt(messaggio.risposte[0].dataEvasione)}</b>
                      </small>
                    </span>
                  </div>
                  </Row>
                
                <Row className="readable-text" style={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: '4px', padding: '1rem' }}>{messaggio.risposte[0].testo}</Row>
              </Col>
            </Row>

          </Container>
        </CardBody>

        <CardFooter>
          <Row className="justify-content-end">
                {messaggio && messaggio.file.length > 0 &&
                    <Button color="info" onClick={() => { downloadAllegato(messaggio.file) }} className="text-left"> 
                      <FontAwesomeIcon icon={faFile} />&nbsp;<span className="smallLabel">Scarica allegato<small></small></span>
                    </Button>
                }

                <Button color="success" onClick={() => {goto({pathname:FAQMANAGER, state:messaggio})}} className="actionsButton">
                  <FontAwesomeIcon icon="star" />
                  <span className="smallLabel"><small>&nbsp;Crea bozza <b>FAQ</b></small></span>
                </Button>
              
                <Button color={messaggio.cancellato === 0 ? 'danger' : 'info'} id="TooltipCancella" onClick={() => { cancella(messaggio, updateMessaggio, goto, messaggio.cancellato) }} className="actionsButton">
                  {messaggio.cancellato === 0 ? (<FontAwesomeIcon icon="trash" />) : (<FontAwesomeIcon icon="trash-restore" />)}
                  <span className="smallLabel"><small>&nbsp;<b>{messaggio.cancellato === 0 ? 'Cancella' : 'Ripristina'} </b></small></span>
                </Button> 
          </Row>   
        </CardFooter>


      </Card>
    </Container>
  );
}

EvasoComponent.propTypes = {
  messaggio: PropTypes.object,
  utenti: PropTypes.array,
  updateMessaggio: PropTypes.func
};

EvasoComponent.defaultProps = {
  messaggio: {},
  utenti:[]
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE,
})

export default withSizes(mapSizesToProps)(EvasoComponent);