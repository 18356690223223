import React from 'react';
import PropTypes from 'prop-types';
import { Nav,  NavLink, NavItem, TabContent, TabPane, Badge} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PaginationComponent from "react-reactstrap-pagination";
import { SEZIONI_NASCOSTE } from '../../constants';
import "bootstrap/dist/css/bootstrap.min.css";


import classnames from 'classnames';
import ContentElementContainer from '../ContentElement';
import './AdminComponent.css';
import { PAGESIZE } from '../../constants';



const AdminComponent = ({context, sezioni, sottosezioni, messaggi, stato, setSezione, setPage, contatore, location: { pathname }}) => {
  

  const setNavlinks = (sezioni) => {
    return sezioni.map((item, index) => {
      if (SEZIONI_NASCOSTE.includes(item.idSezione)) return null;
      return(
        <NavItem key={item.idSezione}>
          <NavLink className={classnames({ active: stato.Sezione === item.idSezione })} onClick={() => { setSezione(item.idSezione)}}>
            {item.small}&nbsp;
            { <Badge color="info">{contatore[index]}</Badge>}
          </NavLink>
        </NavItem>
      );
    });
  }


  const setTabs = (sezioni) => {
    return sezioni.map((item, index) => {
      if (SEZIONI_NASCOSTE.includes(item.idSezione)) return null;
      return(
        <TabPane tabId={item.idSezione} key={item.idSezione}> 
          <ContentElementContainer messaggi={messaggi} sottosezioni={sottosezioni}/>
          {messaggi.length > 0 && <PaginationComponent           
            totalItems={contatore[index]}
            pageSize={PAGESIZE}
            onSelect={(selPage) => {setPage(selPage - 1, item.idSezione)}}
            maxPaginationNumbers={3}
            activePage={stato.page[item.idSezione] + 1 || 0 + 1}/>}
        </TabPane>
      );
    });
  }

  return(
    
    <>
      <Nav tabs style={{ overflowX: 'auto', overflowY:'hidden'}}>
        {sezioni && setNavlinks(sezioni)}
      </Nav>
      <TabContent activeTab={stato.Sezione} >
        {sezioni && setTabs(sezioni, messaggi)}
      </TabContent>
    </>

  );
}
  
AdminComponent.propTypes = {
  context: PropTypes.array,
  sezioni: PropTypes.array,
  sottosezioni:PropTypes.array,
  messaggi: PropTypes.array,
  stato: PropTypes.object,
  setSezione: PropTypes.func,
  setPage: PropTypes.func,
  contatore: PropTypes.any
};

AdminComponent.defaultProps = {
  context: [],
  sezioni:[],
  sottosezioni:[],
  messaggi:[],
};

export default withRouter(AdminComponent);