import { faCopy, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextareaAutosize from 'react-textarea-autosize';
import { Alert, Button, Row } from 'reactstrap';
import styles from './ScimJwt.module.css';
import { WORKFLOW_COVID } from '../../../constants';
import moment from 'moment';


const ScimJwt = ({jwtObj, jwtError, setControllo, controllo}) => {

    const link = jwtObj ? WORKFLOW_COVID + "/support.html?jwt=" + jwtObj.jwt : "";
    
    const dataFmt = (date) => {
        return moment(date).format('DD/MM/YYYY HH:mm');
    } 

    return(
        <>
        <br/>
        {jwtObj && 
        <>
            <Row>
                <div className={styles.ticket_data} >Token generato il {dataFmt(jwtObj.jwtCreation)} </div>
                <TextareaAutosize className="form-control" maxRows={2} minRows={1} value={link} disabled />
            </Row>
            <Row className="justify-content-start" >
                <ol className={styles.ul}>
                    <li>
                        Copia il link riportato
                        &nbsp;
                        <CopyToClipboard text={link} >
                            <Button color="secondary" size="sm"><FontAwesomeIcon icon={faCopy} /><small></small></Button>
                        </CopyToClipboard>
                    </li>
                    <li>
                        Effettua l'accesso al
                        &nbsp;<a href={WORKFLOW_COVID} target="_blank" rel="noopener noreferrer">workflow</a>&nbsp; 
                        con le tue credenziali
                    </li>
                    <li>
                        Incolla il link nella barra degli indirizzi
                    </li>
                    <li>
                        Controlla la data di creazione del token, nel caso fosse scaduto (durata circa di 12 ore) l'utente dovrà effettuare l'accesso per ottenerne uno nuovo &nbsp;
                        <Button id="reloadButton" color="secondary" size="sm" onClick={() => setControllo(!controllo)}><FontAwesomeIcon icon={faSync} /></Button>
                    </li>
                </ol>
            </Row>
        </>
        }
        {jwtError && !jwtObj && <Alert color="info"> {jwtError} &nbsp; <Button color="danger" size="sm" onClick={() => setControllo(!controllo)}>Ricarica</Button> </Alert>}
        </>
    );

}

ScimJwt.propTypes = {
    jwtError: PropTypes.string,
    jwtObj: PropTypes.object,
    controllo: PropTypes.bool,
    setControllo: PropTypes.func
};
  
ScimJwt.defaultProps = {};


export default ScimJwt;