import api from '../api';

const API_MESSAGES = 'count_messages';
const PENDING_MESSAGES = `${API_MESSAGES}_PENDING`;
const FULFILLED_MESSAGES = `${API_MESSAGES}_FULFILLED`;
const REJECTED_MESSAGES = `${API_MESSAGES}_REJECTED`;

const API_FAQS = 'count_faqs';
const PENDING_FAQS = `${API_FAQS}_PENDING`;
const FULFILLED_FAQS = `${API_FAQS}_FULFILLED`;
const REJECTED_FAQS = `${API_FAQS}_REJECTED`;

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  errors: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PENDING_MESSAGES:
    case PENDING_FAQS:
      return {...state, fetching: true, fetched: false};

    case REJECTED_MESSAGES:
    case REJECTED_FAQS:
      return {...initialState, errors: payload};

    case FULFILLED_MESSAGES:
    case FULFILLED_FAQS:
      return { data: payload.data, fetching: false, fetched: true};

    default: return state;
  }
}

export const countMessages = (path, idRuolo) => ({
  type: API_MESSAGES,
  payload: api.countMessages(path, idRuolo)
});

export const countFaqs = (path) => ({
  type: API_FAQS,
  payload: api.countFaqs(path)
});
