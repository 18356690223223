import React from 'react';
import { Container } from 'reactstrap'

const setFaqView = (faq) => {
    if(faq){
        return faq.map((item) => {
            return(
                <Container key={item.idFaq} style={{textAlign:'left'}}>   
                    <h5>FAQ #{item.idFaq}</h5>
                    <p><b>DOMANDA:</b><br/>{item.domanda}</p>
                    <p><b>RISPOSTA:</b><br/>{item.risposta}</p>
                    <hr/>
                </Container>
               
            )
        }
        );
    }
  }

const FaqPrintListContainer = (props) =>
    <div>
       { setFaqView(props.faq) }
    </div>


export default FaqPrintListContainer;