import { Formik } from "formik";
import React from "react";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";
import SideNavComponent from "../Sidenav/SideNavComponent";
import ScimGestioneUtentiComponent from "./ScimGestioneUtentiComponent";
import api from "../../api";
import { trimma } from "../../helpers";

// valori iniziali del form
const initialValues = {
  username: "",
  phone: "",
  email: "",
};

// schema di validazione
const formSchema = Yup.object().shape({
  username: Yup.string(),
  phone: Yup.string(),
  email: Yup.string(),
});

const ScimGestioneUtentiContainer = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={async (values, actions) => {
        try {
          let obj = trimma(values);
          let response = await api.getLdapUserInfo(obj);
          console.log("response");
          console.log(response);
          actions.setStatus({
            users: response.data,
            success: true,
          });
          actions.setSubmitting(false);
        } catch (er) {
          actions.setStatus({
            success: false,
            errore: "Nessun utente trovato con questi parametri di ricerca",
          });
          actions.setSubmitting(false);
        }
      }}
      render={(props) => (
        <Row style={{ margin: "0" }}>
          <Col xs="1" className="hidden-xs-down sidebar">
            <SideNavComponent />
          </Col>
          <Col style={{ overflowX: "auto" }}>
            <ScimGestioneUtentiComponent {...props} />
          </Col>
        </Row>
      )}
    ></Formik>
  );
};

export default ScimGestioneUtentiContainer;
