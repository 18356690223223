
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, FormGroup,  Label } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import './formStyle.css';


const RowTextAreaInput = ({
  field: {...fields},
  form: {touched, errors},
  labelcols, 
  showuntouched, 
  label, 
  ...props
}) => {

  let [content, setContent] = useState('');

  const updateContent= (evt) => {
    setContent(evt.target.value);
  }

  const espressione = (showuntouched || touched[fields.name]) && !!errors[fields.name] ;
  const invalid = espressione ? espressione.toString() : 'false'

  return(
  <FormGroup row>
    <Label md={labelcols} for={fields.name} className="label-color">{label}<br/><small className="text-muted">{content.length} di 4000 caratteri</small></Label>
    <Col md={12 - labelcols}>
      <TextareaAutosize maxLength="4000" minRows={4} className="form-control" onKeyUp={(evt) => updateContent(evt)}
                        {...props} {...fields} invalid={invalid} style={{border:touched[fields.name] && errors[fields.name] ? '2px solid rgba(220,53,69,.9)' : ''}}/>
      {touched[fields.name] && errors[fields.name] && <div className="label-error">{touched[fields.name] && errors[fields.name]}</div>}

    </Col>
  </FormGroup>
);
}
  
RowTextAreaInput.propTypes = {
  field: PropTypes.object.isRequired, // da Formik: name
  form: PropTypes.object.isRequired,  // da Formik: touched, errors
  label: PropTypes.string.isRequired, // etichetta
  labelcols: PropTypes.number,        // num colonne per etichetta
  showuntouched: PropTypes.bool       // mostra errore anche campo non touched (es. numerici)
 };

 RowTextAreaInput.defaultProps = { 
  labelcols: 3,
  showuntouched: false
 };

export default RowTextAreaInput;
