import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { Button, Form, Row, Col, Collapse, Alert} from 'reactstrap';
import { RowSelectInput } from '../form';
import { addSottosezione } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch , faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom';
import { showErrorMsg } from '../../helpers';

import AddFaq from './AddFaq';
import FaqContentElement from './FaqContentElement';
import styles from './FaqManager.module.css';

const FaqManagerComponent = ({
  sectionList,
  subsectionList,
  utente,
  listaFaq,
  values,
  location,
  status,
  handleSubmit,
  isSubmitting,
  isValid,
  errore
}) => {

  const [isOpen, toggle] = useState(location.state === undefined ? false : true);

  return(
    <Fragment>
      <Row>     
        <div className={styles.title}>   
          <h3>Faq Manager</h3>
        </div>
      </Row>
      <Row>
        <div className={styles.subtitle}>
          Selezionare una <strong>sezione</strong> e una <strong>sottosezione</strong> per vedere le FAQ già presenti o crearne una nuova
        </div>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row className={styles.rowLayout}>
          <Col xs="12" sm="8" md="8">
            <Field label="Sezione principale" name="idSezione" list={sectionList} component={RowSelectInput} />
            {values.idSezione !== '99' && <Field label="Sottosezione" name="idSottosezione"
            list={addSottosezione(subsectionList.filter(e => e.idSezione === parseInt(values.idSezione)))} component={RowSelectInput} />}   
          </Col>
          <Col xs="12" sm="4" md="4">
            <Button color="info" type="submit" disabled={isSubmitting || !isValid} onClick={() => toggle(false)}>
              <FontAwesomeIcon icon={faSearch} /><span className={styles.smallLabel}>&nbsp;Cerca tra le Faq</span>
            </Button>
            <Button color="success" onClick={() => toggle(!isOpen)} className={styles.button} disabled={!isValid}>
              <FontAwesomeIcon icon={faPlusCircle} /><span className={styles.smallLabel}>&nbsp;Crea nuova Faq</span>
            </Button>
          </Col>
        </Row>
      </Form>

      <Collapse isOpen={isOpen} className={styles.collapse}>
        <AddFaq idSezione={parseInt(values.idSezione)} messaggio={location.state === undefined ? {} : location.state} idSottosezione={parseInt(values.idSottosezione)} utente={utente[0]}/>
      </Collapse>

      {listaFaq.length > 0 && <FaqContentElement faqs={listaFaq} />}

      {status && status.search && listaFaq.length === 0  &&
        <Alert color="warning"> 
          Nessuna Faq presente nella sezione
        </Alert>  
      }

      {errore && showErrorMsg(errore)}
      </Fragment>
  );
}

FaqManagerComponent.propTypes = {
  listaFaq: PropTypes.array,
  utente: PropTypes.array,
};

FaqManagerComponent.defaultProps = {
  listaFaq: []
};

export default withRouter(FaqManagerComponent);