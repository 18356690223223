import { TYPEFAQ, TYPEMESSAGE, NEWS } from '../constants';

export const HOME = '/';
export const FAQ = '/faq';
export const LOGIN = '/login';
export const UNAUTHORIZED = '/403';

export const NONEVASI = '/' + TYPEMESSAGE + '/nonevasi';
export const EVASI = '/' + TYPEMESSAGE + '/evasi';
export const ALL = '/' + TYPEMESSAGE + '/tutti';
export const MOH = '/' + TYPEMESSAGE + '/moh';
export const MESSAGGIO = '/' + TYPEMESSAGE + '/dettaglio' ;
export const SEND = '/' + TYPEMESSAGE + '/evaso';

export const FAQMANAGER = '/' + TYPEFAQ + '/dashboard';
export const FAQDETAIL = '/' + TYPEFAQ + '/detail';
export const CHANGEFAQ = '/' + TYPEFAQ + '/change';
export const FAQPUBBLICATE = '/' + TYPEFAQ + '/pubblicate';
export const FAQBOZZA = '/' + TYPEFAQ + '/bozza';
export const FAQELIMINATE = '/' + TYPEFAQ + '/eliminate';

export const SEARCHFAQ = '/' + TYPEFAQ + '/research';
export const SEARCHMESSAGE = '/' + TYPEMESSAGE + '/research';

export const NEWSDETAIL = '/' + NEWS;
export const NEWSMANAGER = '/' + NEWS + '/dashboard';
export const NEWSPUBBLICATE = '/' + NEWS + '/pubblicate';
export const NEWSELIMINATE = '/' + NEWS + '/eliminate';
export const NEWSDETTAGLI = '/' + NEWS + '/detail';

export const PUBLICFAQ = '/faq/pubblicate';

//Gestione dell' LDAP
export const SCIMMANGER = '/scim';
export const SCIMREGISTRAZIONE = '/scim/registrazione';
export const SCIMCERCA = '/scim/cerca';
export const SCIMMODIFICA = '/scim/utente';

//Gestione del servizio di Mail
export const MAILMANAGER = '/microservice-mail';
export const MAIL_CREA_APPLICATIVO = '/microservice-mail/applicativo/crea';
export const MAIL_CREA_TEMPLATE = '/microservice-mail/template/crea';


// stampe
export const PRINT_FAQ_LIST = '/printFAQ';




