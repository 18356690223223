import { faArrowLeft, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row } from 'reactstrap';
import { MAILMANAGER } from '../../../navigation/routes';
import { RowCheckInput, RowInput } from '../../form';
import styles from './MailNuovoApplicativo.module.css';

const MailNuovoApplicativoComponent = ({
  values,
  touched,
  errors,
  e,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldError,
  isSubmitting,
  isValid,
  groupList
}) => 
  <Container >
    <Row>
      <Col>      
        <div className={styles.mainContainer}>
          <h4 className={styles.title}>Crea Nuovo Applicativo</h4>
          Inserisci tutte le informazioni dell'applicativo che dovrà utilizzare il servizio di mail 
        </div>
      </Col>
      <Col> 
        <div className={styles.buttonContainer}>
          <Link to={MAILMANAGER}><Button color="warning" size="sm"><FontAwesomeIcon icon={faArrowLeft} /><span className="smallLabel">&nbsp;Indietro<small></small></span></Button></Link>
        </div> 
      </Col>
    </Row>
    <hr/>
    
      <Form onSubmit={handleSubmit} >
        <div>
          <div className={styles.content}>
            <Row>
                <Col sm="8">
                  <Field type="text" label="Nome Applicativo" name="nomeApplicativo" component={RowInput} />
                </Col>
                <Col sm={{size: 3, offset: 1}}>
                  <Field label="Applicativo attivo" name="active" component={RowCheckInput} />
                </Col>
            </Row>     
            <Field type="text" label="Organizzazione" name="organizzazione" component={RowInput} />
          </div>
          <div className={styles.submit}>
            <Button color="success" type="submit" disabled={isSubmitting || !isValid}><FontAwesomeIcon icon={faPlusCircle} />&nbsp;Crea<small></small></Button>
          </div>
        </div>
      </Form>
    {status &&  !status.success &&   <><br/><Alert color="danger">{status.errore} </Alert></>}
    {status && status.success && <><br/><Alert color="success">Applicativo creato correttamente - id applicativo: {status.application.applicationID}</Alert></>}
    
  </Container>

MailNuovoApplicativoComponent.propTypes = {
  groupList: PropTypes.array
};

MailNuovoApplicativoComponent.defaultProps = {};

export default MailNuovoApplicativoComponent;