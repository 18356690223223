import React from 'react';

import { Field } from 'formik';
import { Container, Button, Form } from 'reactstrap';
import { RowInput, RowTextAreaInput } from '../../form';
import { showErrorMsg } from '../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import './AddFaq.css';

const AddFaqComponent = ({
  values,
  touched,
  errors,
  e,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldError,
  isSubmitting,
  isValid,
  errore
}) =>

    <Container >
      <Form onSubmit={handleSubmit}>
        <Field type="text" label="Titolo" name="titolo" component={RowInput} />
        <Field type="textarea" label="Domanda" name="domanda" component={RowTextAreaInput}  maxLength="4000" />    
        <Field type="textarea" label="Risposta" name="risposta" component={RowTextAreaInput} maxLength="4000" />   
        <Button color="success" type="submit" disabled={isSubmitting || !isValid}>
          <FontAwesomeIcon icon={faSave} /><span className="smallLabel">&nbsp;Salva in bozza</span>
        </Button>
        {errore && showErrorMsg(errore)}
      </Form>
    </Container>
  ;


AddFaqComponent.propTypes = {
};

AddFaqComponent.defaultProps = {
};

export default AddFaqComponent;