import React from 'react';
import PropTypes from 'prop-types';

import illustration from './emptyFog.png';
import styles from  './EmptyTicketContainer.module.css';
import { TYPEFAQ, TYPEMESSAGE, NEWS } from '../../constants';

const changeString = (type) => {
    let status
    switch (type) {
        case TYPEFAQ:
            status = {string: 'Nessuna FAQ trovata in questa sezione' };
            break;
        case TYPEMESSAGE:
            status = {string: 'Nessun Messaggio trovato per questa sezione' };
            break;
        case NEWS:
            status = {string: 'Nessuna News trovata in questa sezione'};
            break;
        default:
            status = {string: ''};
            break;
    }
    return (<span className="text-muted"> {status.string} </span>);
}

const EmptyTicketContainer = ({type}) => {
    return(
        <>
            <img src={illustration} className={styles.illustration} alt="illustration"/>
            <br/>
            { changeString(type) }
        </>
        );
}

EmptyTicketContainer.propTypes = {
    type: PropTypes.string
  };
  
EmptyTicketContainer.defaultProps = {
    type: ''
};

export default EmptyTicketContainer;