import React from 'react';
import PropTypes from 'prop-types';
import { Form, Alert, Container, Card, CardHeader, CardFooter, CardBody, CardSubtitle, CardTitle, Button, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { Field } from 'formik';
import { RowInput, RowTextAreaInput, RowSelectInput } from '../../form';
import { MOBILE_SIZE } from '../../../constants';
import moment from 'moment';
import './ChangeFaqComponent.css';
import withSizes from 'react-sizes'
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';



const FaqDetailComponent = ({ 
    faq,
    utente, 
    sezioni, 
    sottosezioni,
    goto,
    e,
    values,
    isMobile, 
    handleSubmit,
    isSubmitting,
    isValid,
    errore
}) => {

  const dataFmt = moment(faq.dataInserimento).format('DD/MM/YYYY HH:mm');

  return (
    <Container style={{ marginTop: '3rem', textAlign: 'left' }}>

      <Card>
        <CardHeader color="info">
          <CardTitle><h3>FAQ &middot; <span style={{ color: ' #007bff ' }}><b>{faq.idFaq}</b></span></h3></CardTitle>
          <CardSubtitle><small>FAQ creata in data: <b>{dataFmt}</b></small></CardSubtitle>
        </CardHeader>

        <Form onSubmit={handleSubmit}>
            <CardBody>
                <Container>
                        <Field type="text" label="Titolo" name="titolo" component={RowInput} />
                        <Field label="Sezione principale" name="idSezione" list={sezioni} component={RowSelectInput} />
                        {values.idSezione !== '99'  && 
                          <Field label="Sottosezione" name="idSottosezione"
                            list={sottosezioni.filter(e => e.idSezione === parseInt(values.idSezione))} component={RowSelectInput} />}
                        <Field type="textarea" label="Domanda" name="domanda" component={RowTextAreaInput}  maxLength="4000" />    
                        <Field type="textarea" label="Risposta" name="risposta" component={RowTextAreaInput} maxLength="4000" />
                </Container>
            </CardBody>
            
            <CardFooter>
            <Row className="justify-content-end">     
                <Button color="info"  type="submit" className="actionsButton" disabled={isSubmitting || !isValid}>
                <FontAwesomeIcon icon={faSave} />
                <span className="smallLabel"><small>&nbsp;Salva</small></span>
                </Button>
            </Row>
            </CardFooter>
            {errore && <Alert > {errore} </Alert>}
        </Form>
      </Card>
    </Container>
  );
}

FaqDetailComponent.propTypes = {
  sezioni: PropTypes.array,
  sottosezioni: PropTypes.array,
  faq: PropTypes.object,
};

FaqDetailComponent.defaultProps = {};

const mapSizesToProps = ({ width, sezioni }) => ({
  isMobile: width <= MOBILE_SIZE,
})

export default withSizes(mapSizesToProps)(FaqDetailComponent);