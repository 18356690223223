import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row, Collapse } from 'reactstrap';
import { AuthContext } from '../../authentication';
import SideNavComponent from '../Sidenav/SideNavComponent';
import EmptyTicketContainer from '../EmptyContainers';
import { withRouter } from 'react-router-dom';
import { NEWS } from '../../constants';
import NewsComponent from './NewsComponent';
import AddNewsContainer from './AddNews/'
import styles from './News.module.css';

import {getNews, setNews, updateNews } from '../../reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const renderNewsList = (news, goto) => {
    return news.map((item, index) => { 
        return(
            <NewsComponent key={index} news={item} goto={goto} />
        );
    });
}

const NewsContainer = ({news, utenti, getNews, setNews, history }) => {

    const {utente} = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const [controller, setController] = useState({})
    const goto = location => history.push(location);

    useEffect(() => {
      getNews()
    }, [controller]);

    return(
        <Row style={{margin:'0'}}>
        <Col xs="1" className="hidden-xs-down sidebar" >
          <SideNavComponent />
        </Col> 
        <Col style={{overflowX:'auto'}}>
          <Container fluid className={styles.newsContainer}>
            
              <h3 className={styles.news_title}>Sezione News</h3>
              <div className={styles.news_subtitle}>
                Aggiungi una news all'elenco. Fare click su di essa per visualizzare il dettaglio, pubblicarla o rimuoverla.
                &nbsp;&nbsp;<Button color="warning" size="sm" onClick={() => { setIsOpen(!isOpen)}}><FontAwesomeIcon icon="plus-circle" /></Button>
              </div>
            
            <Collapse isOpen={isOpen} className={styles.collapse}>
              <AddNewsContainer utente={utente} api={setNews} controlUpdate={setController} toggler={setIsOpen}/>
            </Collapse>
            <div>
              { news && news.length > 0 ?  (renderNewsList(news, goto)) : (<EmptyTicketContainer type={NEWS}/>)}
            </div>
          </Container>
        </Col>
      </Row>
        );
}

NewsContainer.propTypes = {
};
  
NewsContainer.defaultProps = {
};
  
const mapStateToProps = ({ news, context }) => {
  return {
    news: news.data,
    utenti: context.data
  }
  };

export default  connect(mapStateToProps, { getNews, setNews, updateNews })(withRouter(NewsContainer));