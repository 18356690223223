import { createSelector } from 'reselect'

const sezioniSelector = sezioni => sezioni;

export const addSezioni = createSelector(
    sezioniSelector,
    sezioni => {

        if(!sezioni.fetched)
            return sezioni;
        
        if(sezioni.errors && sezioni.errors.length > 0)
            return sezioni;
            
        return {...sezioni, data:[{idSezione:99, nome:'Tutti', small:'Tutti'}, ...sezioni.data]}
    }
  );

