import React from 'react';
import Truncate from 'react-truncate';
import { Container, Row, Col } from 'reactstrap';
import withSizes from 'react-sizes';
import styles from './NewsContentElement/NewsContentElement.module.css';
import moment from 'moment';
import { NEWS, MOBILE_SIZE } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons'

const colori = [
    {id:0, color:'#0275d8', desc:'primary'},
    {id:1, color:'#5cb85c', desc:'success'},
    {id:2, color:'#f0ad4e', desc:'warning'}, 
    {id:3, color:'#d9534f', desc:'danger'}, 
    {id:4, color:'#5bc0de', desc:'info'} ]

const dataFmt = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm');
}

const borderFormatter= (news, styles, colori) => {     
        
    let status = styles.bordered_warning;
    const colore = colori.filter(el => el.color === news.colore)[0];
           
    switch(colore.desc){
        case 'primary':
            status = styles.bordered_primary;
            break;
        case 'success':
            status = styles.bordered_success;
            break;
        case 'info':
            status = styles.bordered_info;
            break;
        case 'danger':
            status = styles.bordered_danger;
            break;
        case 'warning':
            status = styles.bordered_warning;
            break;
        default:
            status = styles.bordered_none;
            break;
    }

    return(status);
}

const statoFormatter = (row, mobile) => {
    let status;
    //cancellato
    if (row.cancellata) {
      status = {icon: 'trash', classname: 'text-danger' };
      return(<span><FontAwesomeIcon size={mobile ? 'sm':'lg'} icon={status.icon} className={status.classname}/></span>);
    } else {
      //non pubblicato
      if (!row.pubblicata){
        return ( <span ><FontAwesomeIcon size={mobile ? 'sm':'lg'} icon={faPause} className='text-warning'/></span>);
      }
      //pubblicato
      if(row.pubblicata){
        status = {icon: 'check-circle', classname: 'text-success' };
        return ( <span ><FontAwesomeIcon size={mobile ? 'sm':'lg'} icon={status.icon} className={status.classname}/></span>);
      }
    }
  }


const dispatchNews = (goto, news) => {
      goto({pathname:`/${NEWS}/${news.idNews}`, state: news});
  }

const NewsComponent = (props) => {
    return(
        <div id="ticket-container" className={`${styles.containerkit} ${borderFormatter(props.news, styles, colori)}`}  
                 onClick={() => {dispatchNews(props.goto, props.news)}}>
                <Container fluid>
                    <Row className={`${styles.ticket_data} justify-content-end`}>
                    <Col>
                        {dataFmt(props.news.dataInserimento)}
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className={styles.ticket_title}>
                            News&nbsp;&ndash;&nbsp;<span className="text-info"><b>{props.news.idNews}</b></span>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: !props.isMobile ? 9: 8}} >
                            <div id="ticket-corpus" className={styles.ticket_corpus}>
                                <Truncate lines={2} ellipsis={<span>...</span>}> {props.news.contenuto} </Truncate>
                            </div>
                        </Col>
                       <Col xs={{size: 1,  offset:!props.isMobile ? 2 : 1}} className={`${styles.ticket_icon} justify-content-end`} >
                            {statoFormatter(props.news, props.isMobile)}
                        </Col>
                    </Row>
                </Container>
            </div>
    );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE,

})
  

export default withSizes(mapSizesToProps)(NewsComponent);