import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field } from 'formik';
import { Container, Button, Form, Alert } from 'reactstrap';

import { RowInput, RowSelectInput, FileUpload, RowTextAreaInput } from '../form';
import { FAQ, HOME } from '../../navigation/routes';
import { addSottosezione, showErrorMsg } from '../../helpers';

import styles from  './Home.module.css';

const colori = [
  {id:0, color:'#0275d8', desc:'primary'},
  {id:1, color:'#5cb85c', desc:'success'},
  {id:2, color:'#f0ad4e', desc:'warning'}, 
  {id:3, color:'#d9534f', desc:'danger'}, 
  {id:4, color:'#5bc0de', desc:'info'} ]

const borderFormatter= (news, styles, colori) => {     
        
  let status = styles.bordered_warning;
  const colore = colori.filter(el => el.color === news.colore)[0];
         
  switch(colore.desc){
      case 'primary':
          status = styles.bd_callout_primary;
          break;
      case 'success':
          status = styles.bd_callout_success;
          break;
      case 'info':
          status = styles.bd_callout_info;
          break;
      case 'danger':
          status = styles.bd_callout_danger;
          break;
      case 'warning':
          status = styles.bd_callout_warning;
          break;
      default:
          break;
  }

  return(status);
}

const renderNewsList = (array, styles) => {
  return array.map((item, index) => { 
    return(
      <div  key={index} className={`${styles.bd_callout} ${borderFormatter(item, styles, colori)}`}>
        <strong className="h5">{item.titolo.toUpperCase()}<br/></strong>
        {item.contenuto}
      </div>
    );
});
  
}

const HomeComponent = ({
  sectionList,
  subsectionList,
  newsList,
  fileuploadapi,
  values,
  touched,
  errors,
  e,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldError,
  isSubmitting,
  isValid,
  errore
}) => 
  <Container className={styles.home}>
    <Form onSubmit={handleSubmit} >
      <div className={styles.title}>
        <h3>Richiesta Supporto Tecnico</h3>
        <div className={styles.subtitle}>
          <div>
            Si chiede la cortesia, prima di inoltrare una richiesta, di <strong>visionare la sezione <Link to={FAQ}>FAQ</Link> per verificare che la risposta non sia già disponibile.</strong>
          </div>
          <div>
            La richiesta sarà presa in carico appena possibile e la risposta sarà inoltrata all'indirizzo e-mail indicato nella richiesta
          </div>
        </div>
      </div>
      {newsList && newsList.length > 0 && renderNewsList(newsList, styles)}
      

    { status && status.inviato ? 
      (
        <div>
          <Alert color="success">Il suo ticket è stato preso in carico. Riceverà una risposta all'indirizzo mail indicato </Alert>
          <div className={styles.submit}>
            <Link to={HOME}>
              <Button color="success" > Torna alla pagina principale </Button>
            </Link>
          </div>
        </div>
      ) : 
      (<div>
        {errore && showErrorMsg(errore) }
        <div className={styles.content}>
          <Field label="Oggetto della Richiesta" name="idSezione" list={sectionList} component={RowSelectInput} />
          {values.idSezione !== '99' && <Field label="Argomento" name="idSottosezione" list={addSottosezione(subsectionList.filter(e => e.idSezione === parseInt(values.idSezione)))} component={RowSelectInput} />}
          <Field type="text" label="Nome" name="nome" component={RowInput} />
          <Field type="text" label="Cognome" name="cognome" component={RowInput} />
          <Field type="text" label="Email" name="email" component={RowInput} />
          <Field type="text" label="Telefono" name="telefono" component={RowInput} />
          <Field type="textarea" label="Messaggio" name="messaggio" component={RowTextAreaInput} />    
        <div className={styles.upload}>
          <Field label="Allega un file" component={FileUpload} api={fileuploadapi} onload={value => setFieldValue('file', value)} />
        </div>
        </div>
        <div className={styles.submit}>
          <Button color="success" type="submit" disabled={isSubmitting || !isValid}>Invia il Messaggio</Button>
        </div>
      </div>
      )
    }
    </Form>
  </Container>

HomeComponent.propTypes = {
  sectionList: PropTypes.array,
  subsectionList: PropTypes.array,
  fileuploadapi: PropTypes.object.isRequired,
  errore: PropTypes.object
};

HomeComponent.defaultProps = {
  sectionList: [],
  subsectionList: [],
  errore: null
};

export default HomeComponent;