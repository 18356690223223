import React from 'react';
import PropTypes from 'prop-types';
import ContentElementComponent from './ContentElementComponent.js';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import EmptyTicketContainer from '../EmptyContainers';
import { TYPEMESSAGE } from '../../constants/index.js';

const renderElement = (array, sottosezioni, utenti, goto) => {
    return array.map((item, index) => { 
        
        const argomento = sottosezioni.filter(argomento => argomento.idSottoSezione === item.idSottosezione)[0];

        return(
            <ContentElementComponent key={index} messaggio={item} argomento={argomento} goto={goto} utenti={utenti}/>
        );
    });
}


const ContentElementContainer = (props) => {


    const goto = location => props.history.push(location);

    return(
        
            <div style={{overflowY:'auto', overflowX:'hidden', paddingBottom:'2rem'}}>
                {props.messaggi && props.messaggi.length > 0 ? (renderElement(props.messaggi, props.sottosezioni, props.utenti, goto)):(<EmptyTicketContainer type={TYPEMESSAGE}/>)}
            </div>

    );

}

ContentElementContainer.propTypes = {
    utenti: PropTypes.array
  };
  
ContentElementContainer.defaultProps = {
    utenti: []
  };
  

const mapStateToProps = ({ context }) => {
    return {
      utenti: context.data,
    }
  };

export default connect(mapStateToProps)(withRouter(ContentElementContainer));