import React, {useContext} from 'react';
import NavbarComponent from './NavbarComponent';
import { AuthContext } from '../../authentication';
import * as routes from '../../navigation/routes';
import moment from 'moment';
import { Alert } from 'reactstrap';
import illustration from '../../assets/moh.png'

const NavbarContainer = () => {
  const {isAuth, logout} = useContext(AuthContext);
  const isShowable = !window.location.href.includes(routes.PRINT_FAQ_LIST);
  const time = new Date();

  return (
       <div>{isShowable 
        ? (<NavbarComponent isAuth={isAuth} doLogout={logout}/>) 
        : (
        <Alert color="info">
        <img src={illustration}  alt="illustration" width="50%" height="20%"  style={{textAlign:'left'}}/>
        <div style={{textAlign:'center', padding:'2rem'}}>
          <h3>FAQ &#9473; BANDO RICERCA FINALIZZATA 2019</h3>
          <h5>Questa estrazione è stata effettuata in data:&nbsp;{moment(time).format('DD/MM/YYYY HH:mm')}</h5>
          </div></Alert>
          )}</div>
  );
};

NavbarContainer.propTypes = {};

NavbarContainer.defaultProps = {};

export default NavbarContainer;