import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'reactstrap';
import styles from './Footer.module.css';

const Footer = () => {
    return(
        <footer className={styles.footer_layout}>
        <Row className={styles.row_layout}>
            <Col md="4">
                <h4>Workflow Della Ricerca</h4>
                <ul className={styles.list_layout}>
                <li><a href="http://ricerca.cbim.it/index.html" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Workflow 2.0</a></li>
                <li><a href="https://moh-it.pure.elsevier.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Scientific IRCCS Network su Scival</a></li>
                </ul>
            </Col>
            <Col md="4">
                <h4>Ministero Della Salute</h4>
                <ul className={styles.list_layout}>
                <li><a href="http://www.salute.gov.it/portale/home.html" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Sito Web</a></li>
                <li><a href="http://www.salute.gov.it/portale/news/p3_2_1.jsp?lingua=italiano&menu=notizie&area=Ricerca%20sanitaria" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Notizie</a></li>
                <li><a href="http://www.salute.gov.it/portale/p5_0.jsp?lingua=italiano&id=58" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Contatti</a></li>
                </ul>
            </Col>
            <Col md="4">
                <h4>CBIM</h4>
                <ul className={styles.list_layout}>
                <li><a href="http://www.cbim.it/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Sito Web</a></li>
                <li><a href="http://www.cbim.it/dove-siamo_new/index.html" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Sede</a></li>
                <li><a href="http://www.cbim.it/workflow-della-ricerca-biomedica_new/index.html" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faAngleDoubleRight}/>&nbsp;Workflow della Ricerca Biomedica</a></li>
                </ul>
            </Col>
        </Row>
        <Row className={styles.row_layout_b}>
            <Col>
                <a href="http://www.mxguarddog.com/it/">junk email filter</a>
            </Col>
        </Row>
        </footer>
        )
}

Footer.propTypes = {};

Footer.defaultProps = {};

 export default Footer;
 