import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateMessaggio, setRisposta } from '../../../reducers';
import { NONEVASI } from '../../../navigation/routes';
import { NORMALRESPONSE } from '../../../constants';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { errors } from '../../form';
import RispostaComponent from './RispostaComponent' ;
import api from '../../../api';
const { OB } = errors;

/**
 * Formik parameters and initial configuration w/ Yum
 */
const initialValues = {
    testo: '',
    file: '',
  };

const formSchema = Yup.object().shape({
    testo: Yup.string().required(OB),
    file: Yup.string()
});

const RispostaContainer = ({ messaggio, status, goto, utente }) => { 

  const [error, setError] = useState();
  
      return (
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={ async (values, actions) => {
          
            try {
              messaggio.flagPresaincarico = 0;
              values = {...values,
                idUtente: utente.idUtente,
                messaggio: messaggio,
                flagTipoRisposta: NORMALRESPONSE
              }
              await setRisposta(values);
              await updateMessaggio(messaggio);
              actions.setSubmitting(false);
              actions.resetForm();
              goto(NONEVASI);
            } catch (er) {
              setError(
              { ...er,
                messaggio: 'Il suo messaggio non è stato recapitato. Tipo di errore: '
              });
              actions.setSubmitting(false);
            }
      }}
      render={props => <RispostaComponent {...props} errore={error} fileuploadapi={api.fileupload} /> }
      >
      </Formik>
  );

  }


  RispostaContainer.propTypes = {
  messaggio: PropTypes.object,
  status: PropTypes.any,
  goto: PropTypes.func,
  utente: PropTypes.object
};

RispostaContainer.defaultProps = {};

const mapStateToProps = ({ context }) => {
  return {}
};

export default connect(mapStateToProps, { updateMessaggio, setRisposta })(withRouter(RispostaContainer));