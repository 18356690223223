import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import api from '../../api';
import FaqPrintListComponent from './FaqPrintListComponent';

const FaqPrintListContainer = () => { 
    
    let [faqIsFetched, faqSetIsFetched] = useState([]);

    useEffect(() => {
        const fetchData = async() => {
          try{
            faqSetIsFetched(await api.getAllFaqPubblicate());
          }catch(error){
            faqSetIsFetched(error)
          }
        }
        fetchData();
      },[])
    
    return(
        <FaqPrintListComponent faq={faqIsFetched.data}/>
    );
}




FaqPrintListContainer.propTypes = {};

FaqPrintListContainer.defaultProps = {};

const mapStateToProps = ({ context }) => {
  return {
    context
  }
};

export default connect(mapStateToProps)(withRouter(FaqPrintListContainer));