import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import withSizes from 'react-sizes';
import { Col, Container, Row } from 'reactstrap';
import { MOBILE_SIZE } from '../../../constants';
import styles from './FaqPublicContentElement.module.css';

const FaqPublicContentElementComponent = ({faq, isMobile, goto}) => {

    const dataFmt = (date) => {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    return(      
            <div id="ticket-container" className={styles.containerkit} >
                <Container fluid>
                    <Row>
                        <Col>
                            <div className={styles.ticket_title}>
                                #&nbsp;<span className="text-info"><b>{faq.idFaq}</b></span>
                                <span className={styles.ticket_dash}>&nbsp;&mdash;&nbsp;</span>
                                {faq.titolo.toUpperCase()}
                            </div>
                        </Col>
                        <Col xs="3">
                            <div className={styles.ticket_title}>
                            <span className={styles.ticket_subtitle}>{faq.sezioneDesc}</span>
                            </div>
                        </Col>
                        <Col className={`${styles.ticket_date} justify-content-end`} xs="2">
                            {dataFmt(faq.dataInserimento)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" >
                            <div id="ticket-corpus" className={styles.ticket_corpus}>
                              <span className={styles.ticket_subtitle}>Domanda:</span> {faq.domanda} 
                            </div>
                        </Col>
                        <Col xs="12" >
                            <div id="ticket-corpus" className={styles.ticket_corpus}>
                              <span className={styles.ticket_subtitle}>Risposta:</span> {faq.risposta} 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
    );
}

FaqPublicContentElementComponent.propTypes = {
    faq: PropTypes.object,
    goto: PropTypes.func
};
  
FaqPublicContentElementComponent.defaultProps = {
  faq: {},
};
  

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE,

})
  

export default withSizes(mapSizesToProps)(FaqPublicContentElementComponent);