import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from  'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { FAQ, PUBLICFAQ } from '../../../navigation/routes';


const setLink = (index, idSezione) => {
    if(index === 0)
        return FAQ
    else
        return `${PUBLICFAQ}/${idSezione}/all`
}

const setNavigationLink = (navArray, idSezione) => {
    return navArray.map( (item, index) => {
        return(
            <Fragment key={index}>
            {index !== navArray.length - 1 ? 
                <BreadcrumbItem  active={index === navArray.length - 1  ? true : false }><Link to={setLink(index, idSezione)}>{item}</Link></BreadcrumbItem>
                : 
                <BreadcrumbItem  active={index === navArray.length - 1  ? true : false }>{item}</BreadcrumbItem>
            }
            </Fragment>
        )
      })
}

const NavigationLink = ({navArray, idSezione}) => {
    return (
        <Breadcrumb>
            {setNavigationLink(navArray, idSezione)}
        </Breadcrumb>
    )
}


NavigationLink.propTypes = {
    navArray: PropTypes.array,
    idSezione: PropTypes.string,
};

NavigationLink.defaultProps = {};

export default NavigationLink;
