import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './SideNav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NONEVASI , FAQMANAGER, EVASI, ALL, MOH, FAQPUBBLICATE, FAQBOZZA, FAQELIMINATE, NEWSMANAGER, SCIMMANGER, MAILMANAGER} from '../../navigation/routes';
import { checkRole } from '../../helpers';
import { AuthContext } from '../../authentication';
import { ADMIN, CBIM } from '../../constants';


const SideNavComponent = () => {

    const { utente } = useContext(AuthContext);

    return(
        <ul className="sidebar nav navbar-nav">
            <li className="arrow"><Link to={NONEVASI}><FontAwesomeIcon icon="envelope" /><br/>Ticket non evasi</Link><br/><br/></li>
            <li className="arrow"><Link to={EVASI}><FontAwesomeIcon icon="envelope-open" /><br/>Ticket evasi</Link><br/><br/></li>
            <li className="arrow"><Link to={ALL}><FontAwesomeIcon icon="inbox" /><br/>Tutti i Ticket</Link><br/><br/></li>
            {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <li className="arrow"><Link to={MOH}><FontAwesomeIcon icon="university" /><br/>Ticket MOH</Link><br/><br/></li>}
            <li className="arrow"><Link to={FAQMANAGER}><FontAwesomeIcon icon="question-circle" /><br/>FAQ Manager</Link><br/><br/></li>
            <li className="arrow"><Link to={FAQPUBBLICATE}><FontAwesomeIcon icon="stamp" /><br/>FAQ pubblicate</Link><br/><br/></li>
            <li className="arrow"><Link to={FAQBOZZA}><FontAwesomeIcon icon="file-alt" /><br/>FAQ in bozza</Link><br/><br/></li>
            <li className="arrow"><Link to={FAQELIMINATE}><FontAwesomeIcon icon="trash" /><br/>FAQ eliminate</Link><br/><br/></li>
            {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <li className="arrow"><Link to={NEWSMANAGER}><FontAwesomeIcon icon="newspaper" /><br/>NEWS Manager</Link><br/><br/></li>}
            {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <li className="arrow"><Link to={SCIMMANGER}><FontAwesomeIcon icon="server" /><br/>LDAP Manager</Link><br/><br/></li>}
            {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <li className="arrow"><Link to={MAILMANAGER}><FontAwesomeIcon icon="at" /><br/>Mail Manager</Link><br/><br/></li>}
        </ul>
    );
}

export default SideNavComponent;