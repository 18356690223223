import React from 'react';

import { Field } from 'formik';
import { Container, Button, Form, Label, Row, Col } from 'reactstrap';
import { RowInput, RowTextAreaInput, RowSelectColorInput } from '../../form';

const colori = [
    {id:0, color:'#0275d8', desc:'primary'},
    {id:1, color:'#5cb85c', desc:'success'},
    {id:2, color:'#f0ad4e', desc:'warning'}, 
    {id:3, color:'#d9534f', desc:'danger'}, 
    {id:4, color:'#5bc0de', desc:'info'} ]

const renderMapColor = (list, chosed) => {
    const colore = list.filter(el => el.color === chosed)[0];
    
    if(colore !== undefined)
        return 'dot-'+ colore.desc;
    else  
        return null;
}

const AddNewsComponent = (props) => {
    return(
        <Container>
        <Form onSubmit={props.handleSubmit}>
            <Field type="text" label="Titolo" name="titolo" component={RowInput} />
            <Field type="textarea" label="Domanda" name="contenuto" component={RowTextAreaInput}  maxLength="4000" />  
            <Row style={{margin:'0', padding:'0'}}>
                <Col md="3">
                    <Label className="label-color">Colore&nbsp;&nbsp;<span className={`dot ${renderMapColor(colori, props.values.colore)}`}></span></Label>  
                </Col>
                <Col md="9">
                    <Field  name="colore"  list={colori} component={RowSelectColorInput} />
                </Col>
            </Row>
            <Button color="success" type="submit" disabled={props.isSubmitting || !props.isValid}>Salva</Button> 
        </Form>
        </Container>
    )
}

AddNewsComponent.propTypes = {};

AddNewsComponent.defaultProps = {};

export default AddNewsComponent;