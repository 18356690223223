import React, { Component } from 'react';
import axios from 'axios';

import api from '../api';

const AuthContext = React.createContext();

const INITIAL_STATE = { isAuth: false, role: '', username: '', idUtente: '', utente:{} };

class AuthProvider extends Component {
  state = INITIAL_STATE;

  login = async (username, password) => {
    const { data } = await api.authenticate(username, password);
    this.setState({ isAuth: true, username: data.email, role: data.idRuolo, idUtente: data.idUtente , utente:data});
    axios.defaults.headers.common['Authorization'] = "Bearer " + data.jwt;
  }
  
  logout = () => {
    this.setState(INITIAL_STATE);
    axios.defaults.headers.common['Authorization'] = undefined;
    delete axios.defaults.headers.common['Authorization'];
  }

  render() {
    return (
      <AuthContext.Provider
        value={{...this.state, login: this.login, logout: this.logout}}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext }