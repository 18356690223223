import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { Button, Form, Row } from 'reactstrap';
import { RowTextAreaInput, FileUpload } from '../../form';



const RispostaComponent = (props) => {

    return(
        <Form onSubmit={props.handleSubmit} >
            <div className="labels-title">Rispondi:</div>
            <div className="text-muted"><small>Inserisci una risposta e allega eventualmente un allegato</small></div>
            <Field type="textarea" label="Risposta Ticket" name="testo" component={RowTextAreaInput}  style={{marginTop:'1rem'}}/>  
            <Field label="Allega un file" component={FileUpload} api={props.fileuploadapi} onload={value => props.setFieldValue('file', value)} />
            <Row className="justify-content-end">
                <Button color="success" type="submit" disabled={props.isSubmitting || !props.isValid}>Invia il Messaggio</Button>
            </Row>
        </Form>
    );
}

RispostaComponent.propTypes = {
    fileuploadapi: PropTypes.object.isRequired,
    errore: PropTypes.object
  };

RispostaComponent.defaultProps = {
    errore: null
};

export default RispostaComponent;