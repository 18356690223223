import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SideNavComponent from '../Sidenav/SideNavComponent';
import FaqManagerComponent from './FaqManagerComponent';
import api from '../../api';
import { addSezione, renameObjectArrayProps } from '../../helpers';

  // schema di validazione
  const formSchema = Yup.object().shape({
    idSezione: Yup.string()
      .test(
        'section', 'Seleziona una sezione',
        val => val !== '99'
      ),
    idSottosezione: Yup.string()
    .test(
      'section', 'Seleziona un argomento',
      val => val !== '99'
    ),
  });

const FaqManagerContainer = ({context, location}) => {

  //valori iniziali del form
  const initialValues = {
    idSezione: location.state === undefined ? '99' : location.state.idSezione,
    idSottosezione: location.state === undefined ? '99' : location.state.idSottosezione
  };

  const startValues = () => {return location.state === undefined ? false : true}

  const [sezioni, setSezioni] = useState([]);
  const [sottoSezioni, setSottosezioni] = useState([]);
  const [error, setError] = useState();
  const [listaFaq, setListaFaq] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [dataSezioni, dataSottosezioni] = await Promise.all([api.getSezioni(), api.getSottosezioni()]);
      setSezioni(dataSezioni.data);
      setSottosezioni(dataSottosezioni.data);
    }
      fetchData();
  },[])

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={formSchema}
    isInitialValid = {startValues()}
    onSubmit={ async (values, actions) => {
        try {
          const dataFaq = await api.getFaqFiltrate(values.idSezione, values.idSottosezione);
          setListaFaq(dataFaq.data);
          actions.setSubmitting(false);
          actions.setStatus({
             search: true
          });
        } catch (er) {
          setError(
          { ...er,
            messaggio: 'Faq non trovate. Tipo di errore: '
          });
          actions.setSubmitting(false);
        }
    }}
    render={props => 
      <Row style={{margin:'0'}}>
        <Col xs="1" className="hidden-xs-down sidebar" >
          <SideNavComponent />
        </Col> 
        <Col style={{overflowX:'auto'}}>
          <Container fluid >
            <FaqManagerComponent {...props} errore={error} listaFaq={listaFaq} utente={context.data} sectionList={renameObjectArrayProps(addSezione(sezioni),"idSezione","nome")} subsectionList={renameObjectArrayProps(sottoSezioni,"idSottoSezione","descrizione")} />
          </Container>
        </Col>
      </Row>
     }
  >
  </Formik>

    )
};

FaqManagerContainer.propTypes = {};

FaqManagerContainer.defaultProps = {};

const mapStateToProps = ({ context }) => {
  return {
    context
  }
};

export default connect(mapStateToProps)(withRouter(FaqManagerContainer));