import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import EvasoComponent from './EvasoComponent';
import { updateMessaggio } from '../../../reducers';
import { NONEVASI } from '../../../navigation/routes';
import SideNavComponent from '../../Sidenav/SideNavComponent';


const EvasoContainer = ({ match, messaggi, utenti, updateMessaggio, history }) => {
  if (messaggi.length === 0) {
    history.push(NONEVASI);
    return null;
  }

  const messaggio = messaggi.filter(messaggio => messaggio.idMessaggio === parseInt(match.params.id))[0];
  const messaggioCloned = { ...messaggio };

  const goto = location => history.push(location);
  return (
    <Row style={{margin:'0'}}>
      <Col xs="1" className="hidden-xs-down sidebar" >
        <SideNavComponent />
      </Col> 
      <Col style={{overflowX:'auto'}}>
        <Container fluid  >
          <EvasoComponent messaggio={messaggioCloned} utenti={utenti} updateMessaggio={updateMessaggio} goto={goto}/>
        </Container>
      </Col>
    </Row>
              
  )


}

EvasoContainer.propTypes = {
  messaggio: PropTypes.object,
  utenti: PropTypes.array
};

EvasoContainer.defaultProps = {
  messaggio: {},
  utenti: []
};

const mapStateToProps = ({ messaggi, context }) => {
  return {
    messaggi: messaggi.data,
    utenti: context.data
  }
};

export default connect(mapStateToProps, { updateMessaggio })(withRouter(EvasoContainer));