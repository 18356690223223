import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { AuthContext } from '../../../authentication';

import FaqDetailComponent from './FaqDetailComponent';
import api from '../../../api'
import SideNavComponent from '../../Sidenav/SideNavComponent';
import { FAQBOZZA } from '../../../navigation/routes';

const FaqDetailContainer = ({ location, history, sezioni, sottosezioni }) => {

  if (sezioni.length === 0 || sottosezioni.length === 0 || location.state === undefined) {
    history.push(FAQBOZZA);
    return null;
  }

  const updateFaq = (faq) => {
    api.setFaq(faq)
  }

  const [message, setMessage] = useState({});
  const {utente} = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async (idMessaggio) => {
      const dataMessage = await api.getMessageByID(idMessaggio);
      setMessage(dataMessage.data);
    }
    if( location.state.idMessaggio !== 0){
      fetchData(location.state.idMessaggio);
    }
  },[])

  const sezione = sezioni.filter(sezione => sezione.idSezione === location.state.idSezione)[0];
  const sottosezione = sottosezioni.filter(sottosezione => sottosezione.idSottoSezione === location.state.idSottosezione)[0];


  const goto = location => history.push(location);
  
  return (
    <Row style={{margin:'0'}}>
      <Col xs="1" className="hidden-xs-down sidebar" >
        <SideNavComponent />
      </Col> 
      <Col style={{overflowX:'auto'}}>
        <Container fluid  >
          <FaqDetailComponent faq={location.state} message={message} updateFaq={updateFaq} utente={utente} sezione={sezione} sottosezione={sottosezione} goto={goto}/>
        </Container>
      </Col>
    </Row>
              
  )
}

FaqDetailContainer.propTypes = {};

FaqDetailContainer.defaultProps = {};

const mapStateToProps = ({ sezioni, sottosezioni }) => {
  return {
    sezioni: sezioni.data,
    sottosezioni: sottosezioni.data
  }
};

export default connect(mapStateToProps)(withRouter(FaqDetailContainer));