import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {  Container, Card, CardHeader, CardFooter, CardBody, CardSubtitle, CardTitle, Button, Row, Col } from 'reactstrap'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { errors } from '../../form';
import AddNewsComponent from '../AddNews/AddNewsComponent';
import api from '../../../api';
import SideNavComponent from '../../Sidenav/SideNavComponent';
import { NEWS } from '../../../constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { OB } = errors;

const changeStatus = async (news, status, op,  goto) => {
    switch(op){

        case 'DEL':
            news.pubblicata = false;
            news.cancellata = status;
            
            break;
        case 'PUB':
            news.pubblicata = status;
          
            break;
        default: 
            break;
    }

    let response = await api.updateNews(news);
    goto({pathname:`/${NEWS}/${news.idNews}`, state: response.data})
    if(op === 'DEL' && response.data.cancellata)
        toast.error("News eliminata!", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    else if (op === 'DEL' && !response.data.cancellata)
        toast.info("News ripristinata!", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    else if (op === 'PUB' && response.data.pubblicata)
        toast.success("News pubblicata!", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    else if (op === 'PUB' && !response.data.pubblicata)
        toast.warn("News rimossa da pubblicate!", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        
}
const NewsContentElementContainer = ({ location, history }) => {

    const news = location.state;

    const dataFmt = moment(news.dataInserimento).format('DD/MM/YYYY HH:mm');
    const [error, setError] = useState();
    const goto = location => history.push(location);
    const initialValues = {
        ...news
      };
    
    const formSchema = Yup.object().shape({
        titolo: Yup.string().required(OB),
        contenuto: Yup.string().required(OB),
        colore: Yup.string().required(OB),
    });
    
    return (
        <Row style={{margin:'0'}}>
        <Col xs="1" className="hidden-xs-down sidebar" >
          <SideNavComponent />
        </Col> 
        <Col>
        <Container style={{ marginTop: '3rem', marginBottom: '3rem', textAlign: 'left' }}>
            <Card>
                <CardHeader color="info">
                    <CardTitle><h3>News &middot; <span style={{ color: ' #007bff ' }}><b>{news.idNews}</b></span></h3></CardTitle>
                    <CardSubtitle><small>News inserita in data: <b>{dataFmt}</b></small></CardSubtitle>
                </CardHeader>

                <CardBody>
                    <Container>
                        <Row>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={formSchema}
                                onSubmit={async (values, actions) => {
                                    try {
                                        let response = await api.updateNews(values);
                                        actions.setSubmitting(false);
                                        actions.resetForm();
                                        goto({pathname:`/${NEWS}/${news.idNews}`, state: response.data})
                                        toast.success("Modifiche effettuate correttamente!", {
                                            position: toast.POSITION.BOTTOM_RIGHT
                                        });
                                    } catch (er) {
                                setError(
                                    { ...er,
                                        messaggio: 'News non creata correttamente. Tipo di errore: '
                                    });
                                    actions.setSubmitting(false);
                                    toast.error("Non e' stato possibile modificare la news, riprovare", {
                                        position: toast.POSITION.BOTTOM_RIGHT
                                    });
                                    }
                                }}
                                    render={props => <AddNewsComponent {...props} errore={error}  /> }
                                >
                                </Formik>
                            
                        </Row>
                    </Container>
                </CardBody>
                <CardFooter>
                    <Row className="justify-content-end">
                        { !news.cancellata &&
                        <Button color={news.pubblicata ? 'warning' : 'success' } onClick={() => { changeStatus(news,!news.pubblicata, 'PUB', goto) }} className="actionsButton">
                            {news.pubblicata ?  (<FontAwesomeIcon icon="ban"  />) :  (<FontAwesomeIcon icon="star" />)}
                            <span className="smallLabel"><small>&nbsp;{news.pubblicata ? 'Rimuovi' : 'Pubblica' }&nbsp;<b>News</b></small></span>
                        </Button>}
                        <Button color={news.cancellata ? 'secondary' : 'danger' } onClick={() => {  changeStatus(news, !news.cancellata, 'DEL',  goto) }} className="actionsButton">
                            {news.cancellata ?  (<FontAwesomeIcon icon="trash-restore" />) : (<FontAwesomeIcon icon="trash" />)}
                            <span className="smallLabel"><small>&nbsp;<b>{news.cancellata ? 'Ripristina' : 'Cancella' }</b></small></span>
                        </Button>

                    </Row>
                </CardFooter>


            </Card>
            <ToastContainer autoClose={2000} />
        </Container>
        </Col>
        
        </Row>
        
    );
}



export default withRouter(NewsContentElementContainer);