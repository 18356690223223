export const MOBILE_SIZE = 768;
export const TYPEFAQ = 'faqmanager';
export const TYPEMESSAGE = 'message';
export const NEWS = 'news';

//Constants for Mail Service
export const REDIRECT = 1;
export const FAQPUBLICATION = 2;
export const NORMALRESPONSE = 3;

//Constants for File Service
export const APPLICATIONFOLDER = "helpdesk";

//Pagination Constants
export const PAGESIZE = 8;
export const FAQPAGESIZE = 4;

//Constants for Roles
export const ADMIN = 1;
export const CBIM = 2;
export const MR_G = 3;
export const MOH_RUOLO = 4;

//LDAP Groups ID
export const LDAP_MOH = "@!45FF.A4AA.EF13.BA5E!0001!C7D8.1EF7!0003!3090.BB76";
export const LDAP_ADMIN = "@!45FF.A4AA.EF13.BA5E!0001!C7D8.1EF7!0003!60B7";
export const LDAP_APP = "@!45FF.A4AA.EF13.BA5E!0001!C7D8.1EF7!0003!D88C.55F3";
export const LDAP_SMS = "@!45FF.A4AA.EF13.BA5E!0001!C7D8.1EF7!0003!6608.F500";
export const TELEFONO_VERIFICATO = "Numero di telefono verificato";

//url
export const WORKFLOW = "https://ricerca2019.cbim.it"
export const WORKFLOW_COVID = "https://ricercacovid19.cbim.it"
export const BACKENDAPI = "https://helpdesk-rest.cbim.it/api/faq/printFAQ"

// Admin
export const SEZIONI_NASCOSTE = [3, 7, 8, 11]; // Sezioni non più visibili