import { faArrowLeft, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row } from 'reactstrap';
import { MAILMANAGER } from '../../../navigation/routes';
import { RowInput, RowArrayInput } from '../../form';
import styles from './MailNuovoTemplate.module.css';

const MailNuovoTemplateComponent = ({
  values,
  touched,
  errors,
  e,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldError,
  isSubmitting,
  isValid,
  groupList
}) => 
  <Container >
    <Row>
      <Col>      
        <div className={styles.mainContainer}>
          <h4 className={styles.title}>Crea Nuovo Template</h4>
          Inserisci tutte le informazioni del template creato per il servizio mail 
        </div>
      </Col>
      <Col> 
        <div className={styles.buttonContainer}>
          <Link to={MAILMANAGER}><Button color="warning" size="sm"><FontAwesomeIcon icon={faArrowLeft} /><span className="smallLabel">&nbsp;Indietro<small></small></span></Button></Link>
        </div> 
      </Col>
    </Row>
    <hr/>
    
      <Form onSubmit={handleSubmit} >
        <div>
          <div className={styles.content}>
            <Field type="text" label="Nome Template" name="nomeTemplate" placeholder="Nome del file in cui è salvato il template"  component={RowInput} />
            
            <FieldArray
            name="params"
            render={arrayHelpers => (
              <div>
                {values.params && values.params.length > 0 ? (
                  values.params.map((friend, index) => (
                    <div key={index}>
                      <Field label={`Paramentro ${index + 1}`} name={`params.${index}`} index={index} remove={arrayHelpers.remove} insert={arrayHelpers.insert} component={RowArrayInput} />
                    </div>
                  ))
                ) : (
                  <Button  onClick={() => arrayHelpers.push('')}>
                    {/* show this when user has removed all params from the list */}
                    Aggiungi parametri
                  </Button>
                )}
              </div>
            )}
          />
          </div>
          <div className={styles.submit}>
            <Button color="success" type="submit" disabled={isSubmitting || !isValid}><FontAwesomeIcon icon={faPlusCircle} />&nbsp;Crea<small></small></Button>
          </div>
        </div>
      </Form>
    {status &&  !status.success &&   <><br/><Alert color="danger">{status.errore} </Alert></>}
    {status && status.success && <><br/><Alert color="success">Template creato correttamente - id template: {status.template.templateID}</Alert></>}
    
  </Container>

MailNuovoTemplateComponent.propTypes = {
  groupList: PropTypes.array
};

MailNuovoTemplateComponent.defaultProps = {};

export default MailNuovoTemplateComponent;