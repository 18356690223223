import api from '../api';

const API = 'faq';
const PENDING = `${API}_PENDING`;
const FULFILLED = `${API}_FULFILLED`;
const REJECTED = `${API}_REJECTED`;

const API_CERCA = 'cerca_faq';
const PENDING_CERCA = `${API_CERCA}_PENDING`;
const FULFILLED_CERCA = `${API_CERCA}_FULFILLED`;
const REJECTED_CERCA = `${API_CERCA}_REJECTED`;

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  errors: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PENDING:
    case PENDING_CERCA:
      return {...state, fetching: true, fetched: false};

    case REJECTED:
    case REJECTED_CERCA:
      return {...initialState, errors: payload};

    case FULFILLED:
      return { data: [...payload.data], fetching: false, fetched: true};
    case FULFILLED_CERCA:
      return { data: [...payload.data.content], fetching: false, fetched: true};
    default: return state;
  }
}

export const getFaq = (idSezione, path, page = 0) => ({
  type: API,
  payload: api.getFaqBySezione(idSezione, path, page)
});

export const getSearchFaq = (searchString, page) => ({
  type: API_CERCA,
  payload: api.getResearchFaqs(searchString, page)
})