import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';

import reducer from '../reducers';

const logger = createLogger({ collapsed: true });

const middleware = [promise, logger];

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;