import { faSearch, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { PUBLICFAQ } from '../../../navigation/routes';
import * as routes from '../../../navigation/routes';
import * as Constant from '../../../constants/'

const GlobalSearch = ({section, history, getSearchMessage, getSearchFaq, printFaq}) => {

    let [searchString, setSearchString]  = useState('');

    const goto = location => history.push(location);


    const doResearch = async (searchString) => {
        try {
            goto({pathname:PUBLICFAQ, state:{ searchString: searchString }});          
        } catch (er) {
            goto({pathname:PUBLICFAQ, state:{error: er}});
        }
    }

    //it triggers by pressing the enter key
    const handleKeypress = e => {
      if (e.charCode === 13) {
        doResearch(searchString);
      }
    };

    return(
        <>
            <InputGroup>
                <Input 
                    bsSize="lg" 
                    onChange={(evt) => {setSearchString(evt.target.value)}}
                    onKeyPress={handleKeypress}
                    placeholder="Cerca..." 
                />
                <InputGroupAddon addonType="append">
                    <Button disabled={searchString.length === 0 } onClick={() => { doResearch(searchString) }} >
                        <FontAwesomeIcon icon={faSearch}/>
                    </Button>
                </InputGroupAddon>
            </InputGroup>
            {/* <div>Visualizzare tutte le FAQ in formato 
                &nbsp;<a href={`.${routes.PRINT_FAQ_LIST}`} target="_blank" rel="noopener noreferrer">HTML</a>&nbsp;
                o in <a href={Constant.BACKENDAPI} target="_blank" rel="noopener noreferrer">PDF</a>
            </div> */}
        </>
    );
}

GlobalSearch.propTypes = {
    section: PropTypes.string,
    printFaq: PropTypes.func
};

GlobalSearch.defaultProps = {};

const mapStateToProps = ({ messaggi }) => {
    return {
      messaggi: messaggi.data,
    }
  }; 

export default connect(mapStateToProps)(withRouter(GlobalSearch));