import { Formik } from 'formik';
import phone from 'phone';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../api';
import { errors } from '../form';
import SideNavComponent from '../Sidenav/SideNavComponent';
import ScimRegistrazioneComponent from './ScimRegistrazioneComponent';
import { groups } from '../../helpers';

const { OB, EMAIL } = errors;

// valori iniziali del form
const initialValues = {
  group:'99',
  firstName: '',
  lastName: '',
  username: '',
  phone: '',
  email: '',
  password: '',
  repeatPassword: '',
  active: true
};

// schema di validazione
const formSchema = Yup.object().shape({
  firstName: Yup.string().required(OB),
  lastName: Yup.string().required(OB),
  email: Yup.string().email(EMAIL).required(OB),
  username: Yup.string().required(OB),
  phone: Yup.string().test(
    'phone',
    'Il numero di telefono deve essere formattato nel seguente formato: +39 ....',
    val => {
      if (val) {
        return val.includes('+') && phone(val)[0]
      }
    }).required(OB),
  password: Yup.string().required(OB),
  repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], "Le due password inserite devono essere uguali").required(OB),
  group: Yup.string()
    .test(
      'section', 'Seleziona un gruppo',
      val => val !== '99'
    ).required(OB),
});

const ScimRegistrazioneContainer = () =>{ 

  const setUserPayload = (infoUtente) => {

    let model = {
      "active": infoUtente.active,
      "displayName": infoUtente.firstName + ' ' + infoUtente.lastName,
      "emails": [
        {
          "primary": true,
          "value": infoUtente.email
        }
      ],
      "name": {
        "familyName": infoUtente.lastName,
        "formatted": infoUtente.firstName + ' ' + infoUtente.lastName,
        "givenName": infoUtente.firstName
      },
      "password": infoUtente.password,
      "userName": infoUtente.username,
      "phoneNumbers":[
        {
          "display": "Numero di telefono non verificato",
          "type": "work",
          "value": phone(infoUtente.phone)[0]
        }
      ]
    }

    return model;
  }
  
  return (    
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={ async (values, actions)  => {

          let payload = setUserPayload(values);
          try{
            await api.signIn(payload, values.group)
            actions.setStatus({
              email: values.email,
              success_reg: true,
              reg_done: true,
            })  
            actions.setSubmitting(false)

          } catch (er) {
            actions.setStatus({
              email: values.email,
              success_reg: false,
              errore: "Credenziali già presenti."
            })  
            actions.setSubmitting(false)
          }
          
        }
      }
      render={props => 
        <Row style={{margin:'0'}}>
          <Col xs="1" className="hidden-xs-down sidebar" >
            <SideNavComponent />
          </Col> 
          <Col style={{overflowX:'auto'}}>
            <Container fluid  >
              <ScimRegistrazioneComponent {...props} groupList={groups} /> 
            </Container>
          </Col>
        </Row> 
      }
    >
    </Formik>
  )
};

ScimRegistrazioneContainer.propTypes = {};

ScimRegistrazioneContainer.defaultProps = {};

export default ScimRegistrazioneContainer;