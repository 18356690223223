import axios from "axios";
import {
  NONEVASI,
  ALL,
  EVASI,
  MOH,
  FAQPUBBLICATE,
  FAQBOZZA,
  FAQELIMINATE,
} from "../navigation/routes";
import { MR_G, MOH_RUOLO, APPLICATIONFOLDER } from "../constants";
// import * as routes from '../navigation/routes'

const apiConfig = {
  // development: "http://localhost:8080/",
  development: "https://helpdesk-rest.cbim.it/",
  production: "https://helpdesk-rest.cbim.it/",
};

//Dove si trova il servizio di file
const microServiceFile = {
  development: "http://192.168.4.225:8083/",
  production: "https://microservice-file.cbim.it/",
};

//TODO collegamento api lambda

const env = process.env.NODE_ENV || "development";
const baseURL = apiConfig[env];
const baseURLmicroServiceFile = microServiceFile[env];
const instance = axios.create({ baseURL });

const authenticate = (email, password) => {
  const url = "auth/login";
  return instance.post(url, null, { auth: { username: email, password } });
};

const getSezioni = () => {
  const url = "api/sezioni";
  return instance.get(url);
};

const getSottosezioni = () => {
  const url = "api/sottosezioni";
  return instance.get(url);
};

const getMessageByID = (idMessaggio) => {
  const url = "api/message/" + idMessaggio;
  return instance.get(url);
};

const setMessaggio = (message) => {
  const url = "api/messaggio";
  return instance.post(url, message);
};

const updateMessaggio = (message) => {
  const url = "api/messaggio";
  return instance.put(url, message);
};

const createRisposta = (response) => {
  const url = "api/risposta";
  return instance.post(url, response);
};

const getMessaggiBySezione = (idSezione, path, page, idRuolo) => {
  let tipoRichiesta = "";

  switch (path) {
    case NONEVASI:
      tipoRichiesta = 0;
      break;
    case EVASI:
      tipoRichiesta = 1;
      break;
    case ALL:
      tipoRichiesta = 2;
      break;
    case MOH:
      tipoRichiesta = 3;
      break;
    default:
      tipoRichiesta = 0;
  }

  let url = "api/messaggi";

  const payload = {
    idSezione: idSezione,
    ministeriale: [MOH_RUOLO, MR_G].includes(idRuolo) ? 1 : 0,
    page: page,
    tipoRichiesta: tipoRichiesta,
  };

  return instance.post(url, payload);
};

const countMessages = (path, idRuolo) => {
  let url;
  const flagMinistero = [MOH_RUOLO, MR_G].includes(idRuolo) ? 1 : 0;
  switch (path) {
    case NONEVASI:
      url = "api/messaggi/nonevasi/count/" + flagMinistero;
      break;
    case EVASI:
      url = "api/messaggi/evasi/count/" + flagMinistero;
      break;
    case ALL:
      url = "api/messaggi/cbim/count/" + flagMinistero;
      break;
    case MOH:
      url = "api/messaggi/moh/count";
      break;
    default:
      url = null;
  }
  return instance.get(url);
};

const countFaqs = (path) => {
  let url;
  switch (path) {
    case FAQBOZZA:
      url = "api/faqs/bozza/count";
      break;
    case FAQPUBBLICATE:
      url = "api/faqs/pubblicate/count";
      break;
    case FAQELIMINATE:
      url = "api/faqs/eliminate/count";
      break;
    default:
      url = null;
  }
  return instance.get(url);
};

const getContext = () => {
  const url = "api/utenti";
  return instance.get(url);
};

//api per sezione interna con controllo JWT
const getFaqFiltrate = (idSezione, idSottosezione) => {
  const url = "api/faq/filtrato/" + idSezione + "/" + idSottosezione;
  return instance.get(url);
};

const getFaqBySezione = (idSezione, path, page) => {
  let url;
  if (idSezione === 99) {
    switch (path) {
      case FAQPUBBLICATE:
        url = "api/faqs/pubblicate/" + page;
        break;
      case FAQBOZZA:
        url = "api/faqs/bozza/" + page;
        break;
      case FAQELIMINATE:
        url = "api/faqs/eliminate/" + page;
        break;
      default:
        url = null;
    }
  } else {
    switch (path) {
      case FAQPUBBLICATE:
        url = "api/faqs/pubblicate/sezione/" + idSezione + "/" + page;
        break;
      case FAQBOZZA:
        url = "api/faqs/bozza/sezione/" + idSezione + "/" + page;
        break;
      case FAQELIMINATE:
        url = "api/faqs/eliminate/sezione/" + idSezione + "/" + page;
        break;
      default:
        url = null;
    }
  }
  return instance.get(url);
};

const getResearchFaqs = (searchString, page) => {
  let url = "api/faq/search/" + searchString + "/" + page;
  return instance.get(url);
};

//api per sezione pubblica FAQ
const getFaqPubblicheFiltrate = (idSezione, idSottosezione, page) => {
  let url;
  if (idSottosezione === "all")
    url = "api/faq/pubblico/filtrato/sezione/" + idSezione + "/" + page;
  else
    url =
      "api/faq/pubblico/filtrato/" +
      idSezione +
      "/" +
      idSottosezione +
      "/" +
      page;
  return instance.get(url);
};

const searchPublicFaqs = (searchString, page) => {
  let url = "api/faq/public/search/" + searchString + "/" + page;
  return instance.get(url);
};

//Set Faq
const setFaq = (faq) => {
  const url = "api/faq";
  return instance.post(url, faq);
};

const getResearchMessages = (searchString, page) => {
  let url = "api/messaggi/search/" + searchString + "/" + page;
  return instance.get(url);
};

const getNews = () => {
  const url = "api/news";
  return instance.get(url);
};

const setNews = (news) => {
  const url = "api/news";
  return instance.post(url, news);
};

const updateNews = (news) => {
  const url = "api/news";
  return instance.put(url, news);
};

const getNewsById = (id) => {
  const url = "api/news/" + id;
  return instance.get(url);
};

const getNewsPubblicate = () => {
  const url = "api/news/pubblicate";
  return instance.get(url);
};

const fileupload = {
  url: baseURLmicroServiceFile,
  process: {
    url: "upload/" + APPLICATIONFOLDER,
    method: "POST",
  },
};

const filedownload = (file) => {
  const url = baseURLmicroServiceFile + JSON.parse(file).fileDownloadUri;
  const type = JSON.parse(file).fileType;
  const fileName = JSON.parse(file).fileName;

  axios(url, {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
  })
    .then((response) => {
      const file = new Blob([response.data], { type: type });
      var FileSaver = require("file-saver");
      FileSaver.saveAs(file, fileName.substring(33));
    })
    .catch((error) => {
      console.log(error);
    });
};

//Gestione Utenti LDAP
const getLdapUserInfo = (searchPayload) => {
  const url = "api/ldap/user";
  return instance.post(url, searchPayload);
};

const signIn = (payload, group) => {
  const url = "api/ldap/registration/" + group;
  return instance.post(url, payload);
};

const changeLdapUserInfo = (payload, group, userId) => {
  const url = "api/ldap/modification/" + group + "/" + userId;
  return instance.post(url, payload);
};

const getValidJwt = (username) => {
  const url = "api/ldap/jwt/" + username;
  return instance.post(url);
};

//Gestione Mail Service
const createApplication = (payload) => {
  const url = "api/mail/application/create";
  return instance.post(url, payload);
};

const createTemplate = (payload) => {
  const url = "api/mail/template/create";
  return instance.post(url, payload);
};

const rigeneraCodice = (email) => {
  const url = "api/ldap/resetQR/" + email;
  return instance.get(url);
};

const getAllFaqPubblicate = () => {
  const url = "api/faq/pubbliche";
  return instance.get(url);
};

const printFaq = () => {
  const url = "api/faq/printFAQ";
  const payload = "http://google.com";

  return instance.post(url, payload);
};

export default {
  authenticate,
  getSezioni,
  getSottosezioni,
  getContext,
  fileupload,
  setMessaggio,
  getMessaggiBySezione,
  countMessages,
  countFaqs,
  updateMessaggio,
  filedownload,
  createRisposta,
  getFaqFiltrate,
  setFaq,
  getFaqBySezione,
  getResearchMessages,
  getResearchFaqs,
  getNews,
  getNewsById,
  setNews,
  updateNews,
  getFaqPubblicheFiltrate,
  searchPublicFaqs,
  getMessageByID,
  getNewsPubblicate,
  getLdapUserInfo,
  signIn,
  changeLdapUserInfo,
  createApplication,
  createTemplate,
  getValidJwt,
  rigeneraCodice,
  getAllFaqPubblicate,
  printFaq,
};
