import api from '../api';

const API_NEWS = 'news';
const PENDING_NEWS = `${API_NEWS}_PENDING`;
const FULFILLED_NEWS = `${API_NEWS}_FULFILLED`;
const REJECTED_NEWS = `${API_NEWS}_REJECTED`;

const API_SINGLENEWS = 'singleNews';
const PENDING_SINGLENEWS = `${API_SINGLENEWS}_PENDING`;
const FULFILLED_SINGLENEWS = `${API_SINGLENEWS}_FULFILLED`;
const REJECTED_SINGLENEWS = `${API_SINGLENEWS}_REJECTED`;


const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  errors: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PENDING_NEWS: 
    case PENDING_SINGLENEWS:
      return {...state, fetching: true, fetched: false};
      
    case FULFILLED_NEWS:
       return { data: [...payload.data], fetching: false, fetched: true};

    case FULFILLED_SINGLENEWS:
      const newData = state.data.map(msg => msg.idMessaggio === payload.data.idMessaggio ? payload.data : msg);
      return { data: newData, fetching: false, fetched: true};

    case REJECTED_NEWS:
    case REJECTED_SINGLENEWS:
      return {...initialState, errors: payload};
      

    default: return state;
  }
}

export const getNews = () => ({
  type: API_NEWS,
  payload: api.getNews() 
});

export const updateNews = (news) => ({
  type: API_SINGLENEWS,
  payload: api.updateNews(news) 
});

export const setNews = (news) => ({
  type: API_SINGLENEWS,
  payload: api.setNews(news) 
});

