import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {  ButtonGroup, Container, Card, CardHeader, CardFooter, CardBody, CardSubtitle, CardTitle, Button, Row, Col, Collapse } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faUniversity, faGear, faAngleUp, faAnglesUp  } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import { NONEVASI, FAQMANAGER } from '../../navigation/routes';
import { MOBILE_SIZE, REDIRECT, ADMIN, CBIM } from '../../constants';
import RispostaContainer from './Risposta/RispostaContainer';
import moment from 'moment';
import './MessaggioComponent.css';
import withSizes from 'react-sizes';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Truncate from 'react-truncate';
import { checkRole } from '../../helpers';

const prendiInCarico = (updatable, utente, updateMessaggio) => {
  updatable.flagPresaincarico = updatable.flagPresaincarico === utente.idUtente ? 0 : utente.idUtente;
  updateMessaggio(updatable);

  if(updatable.flagPresaincarico === utente.idUtente)
    toast.success("Hai preso in carico il messaggio!", {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  else 
    toast.error("Messaggio non più in carico", {
      position: toast.POSITION.BOTTOM_RIGHT
  });
}

const inviaAlMinistero = (updatable, updateMessaggio, goto, status) => {
  confirmAlert({
    title: 'Sei sicuro di voler procedere?',
    message: 'Facendo click su "Conferma" sposterai il ticket #' + updatable.idMessaggio + ' in un`altra sezione di cui non potresti avere accesso.',
    buttons: [
      {
        label: 'Conferma',
        onClick: async () => {
          updatable.flagMinistero = status ===  0 ? 1 : 0;
          updatable.flagPresaincarico = 0;
          await updateMessaggio(updatable);
          goto(NONEVASI);
        }
      },
      {
        label: 'Annulla',
        onClick: () =>  {return false}
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  })
}

const cancella = (updatable, updateMessaggio, goto, status) => {
  confirmAlert({
    title: 'Sei sicuro di voler procedere?',
    message: 'Facendo click su "Conferma" eliminerai il ticket #' + updatable.idMessaggio + '.',
    buttons: [
      {
        label: 'Conferma',
        onClick: () => {
          updatable.cancellato = status === 0 ? 1 : 0;
          updateMessaggio(updatable);
          goto(NONEVASI);
        }
      },
      {
        label: 'Annulla',
        onClick: () =>  {return false}
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  })
    
}

const automaticResponse  = (messaggio, goto, utente) => {
  confirmAlert({
    title: 'Sei sicuro di voler procedere?',
    message: 'Facendo click su "Conferma" si invierà una risposta automatica con reindirizzamento alla sezione FAQ.',
    buttons: [
      {
        label: 'Conferma',
        onClick: async () => {
          let response = {
            testo: "Risposta tramite reindirizzamento automatico alle FAQ",
            file: "",
            idUtente: utente.idUtente,
            messaggio: messaggio,
            flagTipoRisposta: REDIRECT
          }
          await api.createRisposta(response);
          goto(NONEVASI);
        }
      },
      {
        label: 'Annulla',
        onClick: () =>  {return false}
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  })   
}

const downloadAllegato = (file) => {
  api.filedownload(file);
}


//COMPONENT
const MessaggioComponent = ({ messaggio, utente, updateMessaggio, goto, isMobile }) => {

  let [activeStatus, setActiveStatus] = useState(false);
  let [flagProblemaTecnico, setFlagProblemaTecnico ] = useState(false);
  let [flagPriorita, setFlagPriorita ] = useState(0);

  const setPriorita = (priorita) => {
    if (flagPriorita === priorita){
      setFlagPriorita(0);
    } else {
      setFlagPriorita(priorita);
    }
  } 
  
  const triggerModalRisposta = (status) => {
    setActiveStatus(!status);
  }

  const dataFmt = moment(messaggio.dataRicezione).format('DD/MM/YYYY HH:mm');

  return (

    <Container style={{ marginTop: '3rem', marginBottom: '3rem', textAlign: 'left'}}>

      <Card>
        <CardHeader color="info">
          <CardTitle><h3>Ticket &middot; <span style={{ color: ' #007bff ' }}><b>{messaggio.idMessaggio}</b></span></h3></CardTitle>
          <CardSubtitle><small>Ticket ricevuto in data: <b>{dataFmt}</b></small></CardSubtitle>
        </CardHeader>

        <CardBody>
          <Container>
            <Row>
              <Col md="4">
                <Row className="labels">
                  <span className="labels-title">Mittente:</span>&nbsp;<span>{`${messaggio.nome} ${messaggio.cognome}`}</span>
                </Row>
                <Row className="labels">
                <span className="labels-title">Email:</span>&nbsp;<Truncate lines={1} ellipsis={<span>...</span>}><span>{messaggio.email}</span> </Truncate>
                </Row>
                <Row className="labels">
                  <span className="labels-title" >Telefono:</span>&nbsp;<span>{messaggio.telefono}</span>
                </Row>
                <Row className="labels">
                  <span className="labels-title" >Azioni Rapide:</span>&nbsp;
                </Row>
                <Row style={{marginLeft:'4px'}}>
                  <ButtonGroup vertical={isMobile ? false:true} >
                  {messaggio && messaggio.file && messaggio.file.length > 0 &&
                      <Button color="light" onClick={() => { downloadAllegato(messaggio.file) }} className="text-left"> 
                        <FontAwesomeIcon icon={faFile} />&nbsp;<span className="smallLabel">Scarica allegato<small></small></span>
                      </Button>
                  }     
                    <Button color="light" onClick={() => { automaticResponse(messaggio, goto, utente) }} className="text-left">
                      <FontAwesomeIcon icon="question-circle" />&nbsp;<span className="smallLabel">Reindirizza alle FAQ<small></small></span>
                    </Button>
                    <Button color={!flagProblemaTecnico ? 'light' : 'danger'} onClick={() => { setFlagProblemaTecnico(!flagProblemaTecnico) }} className="text-left">
                    <FontAwesomeIcon icon={faGear} />&nbsp;<span className="smallLabel">Problema tecnico {flagProblemaTecnico ? '(ON)' : '(OFF)'}<small></small></span>
                    </Button>
                    <ButtonGroup>
                      <Button color={flagPriorita === 2 ? ('primary') : 'light'} onClick={() => { setPriorita(2) }} active={flagProblemaTecnico === 2} className="text-left">
                      <FontAwesomeIcon icon={faAngleUp} />&nbsp;<span className="smallLabel">Priorità 2<small></small></span>
                      </Button>
                      <Button color={flagPriorita === 1 ? ('danger') : 'light'} onClick={() => { setPriorita(1) }} active={flagProblemaTecnico === 1} className="text-left">
                      <FontAwesomeIcon icon={faAnglesUp} />&nbsp;<span className="smallLabel">Priorità 1<small></small></span>
                      </Button>
                    </ButtonGroup>
                    {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <Button color={messaggio.flagPresaincarico === 0 ? 'light' : 'danger'} onClick={() => { prendiInCarico(messaggio, utente, updateMessaggio, messaggio.flagPresaincarico) }} className="text-left">
                      <FontAwesomeIcon icon="flag" />&nbsp;<span className="smallLabel">{messaggio.flagPresaincarico === 0 ? 'Prendi in carico' : 'Togli flag presa in carico'}<small></small></span>
                    </Button>}
                    <Button color="light" onClick={() => { inviaAlMinistero(messaggio, updateMessaggio, goto, messaggio.flagMinistero)}} className="text-left">
                      <FontAwesomeIcon icon={faUniversity} />&nbsp;<span className="smallLabel">{messaggio.flagMinistero === 0 ? 'Invia al Ministero' : 'Invia a CBIM'}<small></small></span>
                    </Button>
                  </ButtonGroup>
                </Row>
              </Col>
              <Col md="8">
                <Row className="labels"><span className="labels-title">Messaggio:</span></Row>
                <Row className="readable-text" style={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: '4px', padding: '1rem' }}>{messaggio.messaggio}</Row>
              </Col>

            </Row>

          </Container>
        </CardBody>
        
        <CardFooter>

          <Row className="justify-content-end">     

              <Button color="primary"  onClick={() => { triggerModalRisposta(activeStatus) }} className="actionsButton">
                <FontAwesomeIcon icon="paper-plane" />
                  <span className="smallLabel"><small>&nbsp;Rispondi tramite <b>email</b></small></span>
                </Button>

                <Button color="success" onClick={() => { goto({pathname:FAQMANAGER, state:messaggio}); }} className="actionsButton">
                  <FontAwesomeIcon icon="star" />
                  <span className="smallLabel"><small>&nbsp;Crea bozza <b>FAQ</b></small></span>
                </Button>
              
                <Button color={messaggio.cancellato === 0 ? 'danger' : 'info'} id="TooltipCancella" onClick={() => { cancella(messaggio, updateMessaggio, goto, messaggio.cancellato) }} className="actionsButton">
                  {messaggio.cancellato === 0 ? (<FontAwesomeIcon icon="trash" />) : (<FontAwesomeIcon icon="trash-restore" />)}
                  <span className="smallLabel"><small>&nbsp;<b>{messaggio.cancellato === 0 ? 'Cancella' : 'Ripristina'} </b></small></span>
                </Button>
              
          </Row>   
          <Row>
            <Collapse  isOpen={activeStatus} style={{width:'90%', margin:'1rem', padding:'1rem'}}>
              <RispostaContainer messaggio={messaggio} goto={goto} utente={utente} />
            </Collapse>
          </Row>   
          
        </CardFooter>


      </Card>
      <ToastContainer autoClose={2000} />
    </Container>
  );
}

MessaggioComponent.propTypes = {
  messaggio: PropTypes.object,
  utente: PropTypes.object,
  updateMessaggio: PropTypes.func
};

MessaggioComponent.defaultProps = {
  messaggio: {}
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE,
})

export default withSizes(mapSizesToProps)(MessaggioComponent);