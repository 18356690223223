import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import ScimUtente from '../ScimUtente';

const renderElement = (array, goto) => {
    return array.map((item, index) => { 
        
        return(
            <ScimUtente key={index} goto={goto} userInfo={item}/>
        );
    });
}

const ScimList = ({users, history}) => {

    const goto = location => history.push(location);

    return(
        
            <div style={{overflowY:'auto', overflowX:'hidden', paddingBottom:'2rem'}}>
                {users && users.length > 0 && renderElement(users, goto)}
            </div>

    );

}

ScimList.propTypes = {
    users: PropTypes.array
  };
  
  ScimList.defaultProps = {
    users: []
  };
  


export default withRouter(ScimList);