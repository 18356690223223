import React from 'react';
import PropTypes from 'prop-types';
import {  Container, Badge, Card, CardHeader, CardFooter, CardBody, CardSubtitle, CardTitle, Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBan } from '@fortawesome/free-solid-svg-icons'
import { FAQDETAIL, CHANGEFAQ } from '../../../navigation/routes';
import { MOBILE_SIZE , FAQPUBLICATION, ADMIN, CBIM, MR_G} from '../../../constants';
import api from '../../../api';
import moment from 'moment';
import './FaqDetailComponent.css';
import withSizes from 'react-sizes'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { checkRole } from '../../../helpers';

const cancella = (updatable, updateFaq, goto, status) => {
  confirmAlert({
    title: 'Sei sicuro di voler procedere?',
    message: 'Facendo click su "Conferma" eliminerai la FAQ #' + updatable.idFaq + '. Ne sei sicuro?',
    buttons: [
      {
        label: 'Conferma',
        onClick: () => {
          updatable.cancellato = status === 0 ? 1 : 0;
          updatable.pubblicato = 0;
          updateFaq(updatable);
          goto({pathname:FAQDETAIL, state:updatable});
        }
      },
      {
        label: 'Annulla',
        onClick: () =>  {return false}
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  })  
}

const pubblica = (faq, messaggio, updateFaq, goto, status, utente) => {
  confirmAlert({
    title: 'Sei sicuro di voler procedere?',
    message: 'Facendo click su "Conferma" pubblicherai la FAQ #' + faq.idFaq + '.',
    buttons: [
      {
        label: 'Conferma',
        onClick: async () => {
          faq.pubblicato = status === 0 ? 1 : 0;
          updateFaq(faq);
          if(faq.idMessaggio !== 0 && messaggio.risposte.length === 0){
            let  risposta = {
              testo: faq.risposta,
              idUtente: utente.idUtente,
              messaggio: messaggio,
              flagTipoRisposta: FAQPUBLICATION
            }
            await api.createRisposta(risposta);
          }
          goto({pathname:FAQDETAIL, state:faq});
        }
      },
      {
        label: 'Annulla',
        onClick: () =>  {return false}
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  })  
}

const rispondi = (faq, messaggio, updateFaq, goto, status, utente) => {
  confirmAlert({
    title: 'Sei sicuro di voler procedere?',
    message: 'Facendo click su "Conferma" risponderai al Ticket #' + faq.idMessaggio + ' senza pubblicare la FAQ.',
    buttons: [
      {
        label: 'Conferma',
        onClick: async () => {
          faq.cancellato = 1;
          faq.pubblicato = 0;
          updateFaq(faq);
          let  risposta = {
            testo: faq.risposta,
            idUtente: utente.idUtente,
            messaggio: messaggio,
            flagTipoRisposta: FAQPUBLICATION
          }
          await api.createRisposta(risposta);
          goto({pathname:FAQDETAIL, state:faq});
        }
      },
      {
        label: 'Annulla',
        onClick: () =>  {return false}
      }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  })  
}

const FaqDetailComponent = ({ faq, message, sezione, updateFaq, goto, isMobile, sottosezione, utente }) => {

  const dataFmt = moment(faq.dataInserimento).format('DD/MM/YYYY HH:mm');

  return (

    <Container style={{ marginTop: '3rem', textAlign: 'left' }}>

      <Card>
        <CardHeader color="info">
          <CardTitle><h3>FAQ &middot; <span style={{ color: ' #007bff ' }}><b>{faq.idFaq}</b></span></h3></CardTitle>
          <CardSubtitle><small>FAQ creata in data: <b>{dataFmt}</b></small></CardSubtitle>
        </CardHeader>

        <CardBody>
          <Container>
          <Row>
              <Col sm="4" className="labels">
                <span className="labels-title">Titolo:</span>&nbsp;<span>{faq.titolo}</span>
              </Col>
              <Col sm="4" className="labels">
                <span className="labels-title">Sezione:</span>&nbsp;<span>{sezione.nome}</span>
              </Col>
              <Col sm="4" className="labels">
                <span className="labels-title">Sottosezione:</span>&nbsp;<span>{sottosezione.descrizione}</span>
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <Row className="labels"><span className="labels-title">Domanda:</span></Row>
                <Row className="readable-text" style={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: '4px', padding: '1rem' }}>{faq.domanda}</Row>
              </Col>
              {!isMobile && <Col sm="2"></Col>}
              <Col sm="5">
                <Row className="labels"><span className="labels-title">Risposta:</span></Row>
                <Row className="readable-text" style={{ border: '1px solid rgba(0,0,0,0.3)', borderRadius: '4px', padding: '1rem' }}>{faq.risposta}</Row>
              </Col>
            </Row>

          </Container>
        </CardBody>
        
        <CardFooter>
          <Row className="justify-content-end">
            {faq.cancellato === 0 && faq.pubblicato === 0 && faq.idMessaggio > 0 && checkRole(utente.idRuolo, [ADMIN, CBIM, MR_G]) && <Button color='primary' id="TooltipRispondi" onClick={() => { rispondi(faq, message, updateFaq, goto, faq.pubblicato, utente) }} className="actionsButton">
              <FontAwesomeIcon icon="paper-plane" />
              <span className="smallLabel"><small>&nbsp;<b>Rispondi tramite email</b></small></span>
            </Button> }

            {faq.cancellato === 0 && checkRole(utente.idRuolo, [ADMIN, CBIM, MR_G]) && <Button color={faq.pubblicato === 0 ? 'success' : 'warning'} id="TooltipCancella" onClick={() => { pubblica(faq, message, updateFaq, goto, faq.pubblicato, utente) }} className="actionsButton">
              {faq.pubblicato === 0 ? (<FontAwesomeIcon icon="star" />) : (<FontAwesomeIcon icon={faBan} />)}
              <span className="smallLabel"><small>&nbsp;<b>{faq.pubblicato === 0 ? 'Pubblica FAQ' : 'Rimuovi'} </b></small></span>
            </Button> }

            {faq.cancellato === 0 && <Button color="info" onClick={() => { goto({pathname:CHANGEFAQ , state:faq}) }} className="actionsButton">
              <FontAwesomeIcon icon={faEdit} />
              <span className="smallLabel"><small>&nbsp;Modifica</small></span>
            </Button>}
          
            <Button color={faq.cancellato === 0 ? 'danger' : 'secondary'} id="TooltipCancella" onClick={() => { cancella(faq, updateFaq, goto, faq.cancellato) }} className="actionsButton">
              {faq.cancellato === 0 ? (<FontAwesomeIcon icon="trash" />) : (<FontAwesomeIcon icon="trash-restore" />)}
              <span className="smallLabel"><small>&nbsp;<b>{faq.cancellato === 0 ? 'Cancella' : 'Ripristina'} </b></small></span>
            </Button>
          </Row>
          <Row className="justify-content-end" style={{marginTop: 5}}>
            {message.risposte !== undefined && message.risposte.length === 0 && 
              <Badge color="warning">Pubblicando questa FAQ risponderai anche al messaggio #{message.idMessaggio} </Badge>}
          </Row>
          
        </CardFooter>
      </Card>
    </Container>
  );
}

FaqDetailComponent.propTypes = {
  faq: PropTypes.object,
  message: PropTypes.object,
  utente: PropTypes.object,
  updateFaq: PropTypes.func,
  sezione: PropTypes.object,
  sottosezione: PropTypes.object
};

FaqDetailComponent.defaultProps = {
  faq: {}
};

const mapSizesToProps = ({ width, sezioni }) => ({
  isMobile: width <= MOBILE_SIZE,
})

export default withSizes(mapSizesToProps)(FaqDetailComponent);