import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { NEWS, TYPEFAQ, TYPEMESSAGE } from '../../constants';
import { SEARCHFAQ, SEARCHMESSAGE } from '../../navigation/routes';
import { getSearchFaq, getSearchMessage } from '../../reducers';
import styles from './SearchBar.module.css';
import { trimma } from '../../helpers';


const changeString = (section) => {
    let status
    switch (section) {
        case TYPEFAQ:
            status = {string: 'Cerca tra le FAQ' };
            break;
        case TYPEMESSAGE:
            status = {string: 'Cerca tra i messaggi' };
            break;
        case NEWS:
            status = {string: 'Ricerca news al momento disabilitata' };
            break;
        default:
            status = {string: 'Ricerca non disponibile in questa sezione'};
            break;
    }
    return ( status.string );
}


const SearchBar = ({section, history, getSearchMessage, getSearchFaq, messaggi}) => {

    let [searchString, setSearchString]  = useState('');

    const goto = location => history.push(location);

    const doResearch = async (section, searchString) => {
        try {
            let response
            if(section === TYPEFAQ){
                response = await getSearchFaq(searchString, 0);
                goto({pathname:SEARCHFAQ, state:{data: response.value.data, section: section, searchString: searchString}});
            }
            if(section === TYPEMESSAGE){
                response = await getSearchMessage(searchString, 0);
                goto({pathname:SEARCHMESSAGE, state:{data: response.value.data, section: section, searchString: searchString}});
            }
        } catch (er) {
            if(section === TYPEFAQ)
                goto({pathname:SEARCHFAQ, state:{error: er}});
            if(section === TYPEMESSAGE)
                goto({pathname:SEARCHMESSAGE, state:{error: er}});
        }
    }

    //it triggers by pressing the enter key
    const handleKeypress = e => {
        if (e.charCode === 13) {
            doResearch(section, searchString.trim());
        }
        };

    return(
        <InputGroup className={styles.searchBarWidth}>
            <Input 
                onChange={(evt) => {setSearchString(evt.target.value)}} 
                onKeyPress={handleKeypress}
                placeholder={changeString(section)} 
            />
            <InputGroupAddon addonType="append">
                <Button disabled={searchString.length === 0 || section === NEWS || section === undefined} onClick={() => { doResearch(section, searchString.trim())}}  className={styles.search_button}>
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
            </InputGroupAddon>
        </InputGroup>
    );
}

SearchBar.propTypes = {
    section: PropTypes.string,
};

SearchBar.defaultProps = {};

const mapStateToProps = ({ messaggi }) => {
    return {
      messaggi: messaggi.data,
    }
  }; 

export default connect(mapStateToProps, { getSearchMessage, getSearchFaq })(withRouter(SearchBar));