import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from '../../authentication';
import AppRoutes from '../../navigation/AppRoutes';
import Navbar from '../Navbar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faEnvelopeOpen, faInbox, faUniversity, faQuestionCircle, 
         faStamp, faFileAlt, faTrash, faPaperPlane, faTrashRestore, faFlag, faStar, 
         faCheckCircle, faExclamationTriangle, faNewspaper, faBan, faPlusCircle, faServer, faSearch, faUserCheck, 
         faFilePdf, faUserTimes, faAt, faQrcode, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './App.css';

library.add(faEnvelope, faQrcode, faEnvelopeOpen, faInbox, faUniversity, faQuestionCircle, faStamp, faSearch, faFileAlt, 
  faFilePdf, faTrash, faUserCheck, faArrowLeft, faUserTimes, faPaperPlane, faTrashRestore, faPlusCircle, faFlag, faStar, faCheckCircle, faExclamationTriangle, faNewspaper, faBan, faServer, faAt)

const AppComponent = () => {

  return(
    <div className="App">
    <AuthProvider>
      <Router>
        <>
          <Navbar />
          <AppRoutes />
        </>
      </Router>
    </AuthProvider>
  </div>
  );
}
  

  

AppComponent.propTypes = {};

AppComponent.defaultProps = {};

export default AppComponent;