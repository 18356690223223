import api from '../api';

const API_MESSAGGI = 'messaggi';
const PENDING_MESSAGGI = `${API_MESSAGGI}_PENDING`;
const FULFILLED_MESSAGGI = `${API_MESSAGGI}_FULFILLED`;
const REJECTED_MESSAGGI = `${API_MESSAGGI}_REJECTED`;

const API_MESSAGGIO = 'messaggio';
const PENDING_MESSAGGIO = `${API_MESSAGGIO}_PENDING`;
const FULFILLED_MESSAGGIO = `${API_MESSAGGIO}_FULFILLED`;
const REJECTED_MESSAGGIO = `${API_MESSAGGIO}_REJECTED`;

const API_RISPOSTA = 'risposta';
const PENDING_RISPOSTA = `${API_RISPOSTA}_PENDING`;
const FULFILLED_RISPOSTA = `${API_RISPOSTA}_FULFILLED`;
const REJECTED_RISPOSTA = `${API_RISPOSTA}_REJECTED`;

const API_CERCA = 'cerca_messaggio';
const PENDING_CERCA = `${API_CERCA}_PENDING`;
const FULFILLED_CERCA = `${API_CERCA}_FULFILLED`;
const REJECTED_CERCA = `${API_CERCA}_REJECTED`;

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  errors: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PENDING_MESSAGGI: 
    case PENDING_MESSAGGIO:
    case PENDING_RISPOSTA:
    case PENDING_CERCA:
      return {...state, fetching: true, fetched: false};
      
    case FULFILLED_MESSAGGI:
       return { data: [...payload.data], fetching: false, fetched: true};
    case FULFILLED_CERCA:
       return { data: [...payload.data.content], fetching: false, fetched: true};

    case FULFILLED_MESSAGGIO:
    case FULFILLED_RISPOSTA:
      const newData = state.data.map(msg => msg.idMessaggio === payload.data.idMessaggio ? payload.data : msg);
      return { data: newData, fetching: false, fetched: true};

    case REJECTED_MESSAGGI:
    case REJECTED_MESSAGGIO:
    case REJECTED_RISPOSTA:
    case REJECTED_CERCA:
      return {...initialState, errors: payload};
      

    default: return state;
  }
}

export const getMessaggi = (id, path, page, idRuolo) => ({
  type: API_MESSAGGI,
  payload: api.getMessaggiBySezione(id, path, page, idRuolo) 
});

export const updateMessaggio = (messaggio) => ({
  type: API_MESSAGGIO,
  payload: api.updateMessaggio(messaggio) 
});

export const setRisposta = (risposta) => ({
  type: API_RISPOSTA,
  payload: api.createRisposta(risposta)
})

export const getSearchMessage = (searchString, page) => ({
  type: API_CERCA,
  payload: api.getResearchMessages(searchString, page)
})


