import React from 'react';
import PropTypes from 'prop-types';
import { Nav,  NavLink, NavItem, TabContent, TabPane, Badge} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PaginationComponent from "react-reactstrap-pagination";

import classnames from 'classnames';
import FaqContentElementContainer from '../FaqContentElement';
import './FaqListComponent.css';
import { FAQPAGESIZE } from '../../../constants';



const FaqListComponent = ({context, sezioni, sottosezioni, faqs, stato, setSezione, setPage, contatore, location: { pathname }}) => {
  
  const setNavlinks = (sezioni) => {
    return sezioni.map((item, index) => {
        return(
          <NavItem key={item.idSezione}>
            <NavLink className={classnames({ active: stato.Sezione === item.idSezione })} onClick={() => { setSezione(item.idSezione)}}>
              {item.small}&nbsp;
              {<Badge color="info">{contatore[index]}</Badge>}
            </NavLink>
          </NavItem>
        );
      }
    );
  }


  const setTabs = (sezioni) => {
    return sezioni.map((item, index) => {
        return(
          <TabPane tabId={item.idSezione} key={item.idSezione}> 
            <FaqContentElementContainer faqs={faqs} sottosezioni={sottosezioni}/>
            {faqs.length > 0 && <PaginationComponent           
              totalItems={contatore[index]}
              pageSize={FAQPAGESIZE}
              onSelect={(selPage) => {setPage(selPage - 1, item.idSezione)}}
              maxPaginationNumbers={3}
              activePage={stato.page[item.idSezione] + 1 || 1}/>}
          </TabPane>
        );
      }
    );
  }

  return(
    
    <>
      <Nav tabs style={{ overflowX: 'auto', overflowY:'hidden'}}>
        {sezioni && setNavlinks(sezioni)}
      </Nav>
      <TabContent activeTab={stato.Sezione} >
        {sezioni && setTabs(sezioni, faqs)}
      </TabContent>
    </>

  );
}
  
FaqListComponent.propTypes = {
  context: PropTypes.array,
  sezioni: PropTypes.array,
  sottosezioni:PropTypes.array,
  faqs: PropTypes.array,
  stato: PropTypes.object,
  setSezione: PropTypes.func,
  setPage: PropTypes.func,
  contatore: PropTypes.any
};

FaqListComponent.defaultProps = {
  context: [],
  sezioni:[],
  sottosezioni:[],
  faqs:[],
};

export default withRouter(FaqListComponent);