import React, { useEffect, useState } from 'react';
import PaginationComponent from "react-reactstrap-pagination";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Badge, Col, Row } from 'reactstrap';
import { PAGESIZE, TYPEFAQ, TYPEMESSAGE } from '../../constants';
import { getSearchFaq, getSearchMessage } from '../../reducers';
import ContentElementContainer from '../ContentElement';
import FaqContentElementContainer from '../FaqManager/FaqContentElement';
import SideNavComponent from '../Sidenav/SideNavComponent';
import styles from './SearchOutput.module.css';





const showResult = (section, results, messaggi, faqs ,sottosezioni, page, setPage) => {
    switch (section) {
        case TYPEFAQ:
            return (
                <>
                <h4 className={styles.titleLayout}>Risultati della ricerca <Badge color="info" pill>{results.totalElements}</Badge></h4>
                <FaqContentElementContainer faqs={faqs} />
                {results.content.length > 0 && <PaginationComponent           
                    totalItems={results.totalElements}
                    pageSize={PAGESIZE}
                    onSelect={(selPage) => {setPage(selPage - 1)}}
                    maxPaginationNumbers={3}
                    activePage={page + 1}/>}
                </>    
            );
        case TYPEMESSAGE:
            return (
                <>
                <h4 className={styles.titleLayout}>Risultati della ricerca <Badge color="info" pill>{results.totalElements}</Badge></h4>
                <ContentElementContainer messaggi={messaggi} sottosezioni={sottosezioni.data}/>
                {results.content.length > 0 && <PaginationComponent           
                    totalItems={results.totalElements}
                    pageSize={PAGESIZE}
                    onSelect={(selPage) => {setPage(selPage - 1)}}
                    maxPaginationNumbers={3}
                    activePage={page + 1}/>}
                </>
            );
        default:
            break;
    }
}

const showError = () => {
    return(<div>Nessun risultato dalla ricerca</div>)
}

const SearchOutput = ({location, sottosezioni, messaggi, faqs , getSearchMessage, getSearchFaq}) => {
    const [page, setPage] = useState(0);

    useEffect(() => {
        switch (location.state.section) {
            case TYPEFAQ:
                getSearchFaq(location.state.searchString, page);
                break;
            case TYPEMESSAGE:
                getSearchMessage(location.state.searchString, page);
                break;
            default:
                break;
        }
    }, [page]);

    return(
        <Row style={{margin:'0'}}>
            <Col xs="1" className="hidden-xs-down sidebar" >
            <SideNavComponent />
            </Col> 
            <Col>
                {location.state.error === undefined ? 
                showResult(location.state.section, location.state.data, messaggi.data, faqs.data, sottosezioni, page, setPage) : 
                showError()}
            </Col>
        </Row>
    )
}

SearchOutput.propTypes = {};

SearchOutput.defaultProps = {};

const mapSizesToProps = ({ sottosezioni, messaggi, faqs }) => ({
    sottosezioni,
    messaggi,
    faqs,
})

export default connect(mapSizesToProps, { getSearchMessage, getSearchFaq })(withRouter(SearchOutput));