import { combineReducers } from 'redux';

import contextReducer, { fetchStart } from './contextReducer';
import sezioniReducer, { getSezioni } from './sezioniReducer';
import sottosezioniReducer, { getSottosezioni } from './sottosezioniReducer';
import messaggiReducer, { getMessaggi, updateMessaggio, setRisposta, getSearchMessage } from './messaggiReducer';
import contatoriReducer, { countMessages, countFaqs } from './contatoriReducer';
import faqReducer, { getFaq, getSearchFaq } from './faqReducer';
import publicFaqReducer, { getPublicFaq } from './publicFaqReducer';
import newsReducer, { getNews, updateNews, setNews } from './newsReducer';



export default combineReducers({
  context: contextReducer,
  sezioni: sezioniReducer,
  sottosezioni: sottosezioniReducer,
  messaggi: messaggiReducer,
  contatori: contatoriReducer,
  faqs: faqReducer,
  publicFaqs: publicFaqReducer,
  news: newsReducer
});

export {
  fetchStart,
  getSezioni,
  getSottosezioni,
  getMessaggi,
  countMessages,
  countFaqs,
  updateMessaggio,
  setRisposta,
  getFaq,
  getSearchFaq,
  getSearchMessage,
  getNews,
  updateNews,
  setNews,
  getPublicFaq
};