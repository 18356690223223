import React, {useState, useEffect} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import HomeComponent from './HomeComponent';
import Footer from '../Footer';
import api from '../../api';
import { errors } from '../form';
import { addSezione, renameObjectArrayProps } from '../../helpers';
const { OB, EMAIL } = errors;

// valori iniziali del form
const initialValues = {
  idSezione: '99',
  idSottosezione: '99',
  cancellato: '0',
  nome: '',
  cognome: '',
  email: '',
  telefono: '',
  messaggio: '',
  file: '',
  flagMinistero: '0'
};

// schema di validazione
const formSchema = Yup.object().shape({
  idSezione: Yup.string()
    .test(
      'section', 'Seleziona una sezione',
      val => val !== '99'
    ),
  idSottosezione: Yup.string()
  .test(
    'section', 'Seleziona un argomento',
    val => val !== '99'
  ),
  nome: Yup.string().required(OB),
  cognome: Yup.string().required(OB),
  email: Yup.string().email(EMAIL).required(OB),
  telefono: Yup.string()
    .test(
      'phone', 'Numero non valido',
      val => {
        const reg = /^\+?\d+$/;
        if (val && val.length > 6) {
          const phone = val.replace(/\s/g, '');
          return phone.length > 6 && reg.test(phone);
        }
      })
    .required(OB),
    messaggio: Yup.string().required(OB),
    file: Yup.string()
});

const HomeContainer = () =>{ 
  const [sezioni, setSezioni] = useState([]);
  const [sottoSezioni, setSottosezioni] = useState([]);
  const [news, setNewsPubblicate] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const [dataSezioni, dataSottosezioni, dataNews] = await Promise.all([api.getSezioni(), api.getSottosezioni(), api.getNewsPubblicate()]);
      setSezioni(dataSezioni.data.sort((a, b) => b.idSezione - a.idSezione));  // ordine decrescente per lasciare come prima l'ultima sezione
      setSottosezioni(dataSottosezioni.data);
      setNewsPubblicate(dataNews.data);
    }
    fetchData();
  },[])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={ async (values, actions) => {
          try {
            await api.setMessaggio(values);
            actions.setSubmitting(false);
            actions.resetForm();
            actions.setStatus({
              inviato: true
            });
          } catch (er) {
            setError(
            { ...er,
              messaggio: 'Il suo messaggio non è stato recapitato. Tipo di errore: '
            });
            actions.setSubmitting(false);
          }   
      }}
      render={props =>
        <>
          <HomeComponent {...props} errore={error} newsList={renameObjectArrayProps(news)} sectionList={renameObjectArrayProps(addSezione(sezioni),"idSezione","nome")} subsectionList={renameObjectArrayProps(sottoSezioni,"idSottoSezione","descrizione")} fileuploadapi={api.fileupload} /> 
          <Footer />
        </>
      }
    >
    </Formik>

  )
};

HomeContainer.propTypes = {};

HomeContainer.defaultProps = {};

export default HomeContainer;