import React from 'react';
import { withRouter } from 'react-router-dom';
import { TYPEFAQ } from '../../../constants';
import EmptyTicketContainer from '../../EmptyContainers';
import FaqPublicContentElementComponent from './FaqPublicContentElementComponent';


const renderElement = (array, goto) => {
    return array.map((item, index) => {     
        return(
            <FaqPublicContentElementComponent key={index} faq={item} goto={goto} />
        );
    });
}

const FaqPublicContentElementContainer = (props) => {
    
    const goto = location => props.history.push(location);

    return(
        <div style={{overflowY:'auto', overflowX:'hidden', paddingBottom:'2rem'}}>
            {props.faqs && props.faqs.length > 0 ? renderElement(props.faqs, goto) : (<EmptyTicketContainer type={TYPEFAQ} />)}
        </div>
    );
}

export default withRouter(FaqPublicContentElementContainer);