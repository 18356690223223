import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import Loader from '../../Loader';
import FaqListComponent from './FaqListComponent';
import { fetchStart, getSezioni, getFaq, countFaqs, getSottosezioni } from '../../../reducers';
import { addSezioni } from '../../../selectors';
import './FaqListContainer.css';
import SideNavComponent from '../../Sidenav/SideNavComponent';

class FaqListContainer extends Component {

  state = {
    Sezione: 99,
    page: {},
    interval: null,
    timeToRefresh: false,
  };

  componentDidMount() {
    const { fetchStart, getSezioni, getSottosezioni, getFaq, countFaqs, history } = this.props;
    fetchStart();
    getSezioni();
    getSottosezioni();
    getFaq(this.state.Sezione, history.location.pathname, this.state.page[this.state.Sezione]);
    countFaqs(history.location.pathname);

    //setta l'aggiornamento automatico della pagina
    try {
      let interval = setInterval(async () => {
        this.setState({timeToRefresh: true});
      }, 120000);
      this.setState({interval: interval})
    }
    catch(e){
      console.log(e);
    }
  }

  //nuove chiamate alle api per aggiornare la pagina
  componentDidUpdate(prevProps, prevState){

    if( this.state.timeToRefresh ){
        const { getFaq, countFaqs, history } = this.props;
        getFaq(this.state.Sezione, history.location.pathname, this.state.page[this.state.Sezione]);
        countFaqs(history.location.pathname);
        this.setState({timeToRefresh: false});

    } else {
    
      if (this.state.Sezione !== prevState.Sezione){
        const { getFaq, history } = this.props;
        getFaq(this.state.Sezione, history.location.pathname, this.state.page[this.state.Sezione]);

      }

      if( this.state.page !== prevState.page ){
        const { getFaq, history } = this.props;
        getFaq(this.state.Sezione, history.location.pathname, this.state.page[this.state.Sezione]);

      } 
    }
  }

  //cancello il timer quando il componente non è più in uso
  componentWillUnmount(){
    try {
      clearInterval(this.state.interval)
    }
    catch(e){
      console.log(e);
    }
  }

  toggleSezione = (idSezione) => {
    this.setState(({ Sezione: idSezione }));
  };

  togglePage = (pageSez, idSezione) => {
    this.setState(({ page: {[idSezione]: pageSez} }));
  };

  render(props) {
    const { context, sezioni, sottosezioni, faqs, contatori } = this.props;
    return (
      
          <Row style={{margin:'0'}}>
            <Col xs="1" className="hidden-xs-down sidebar" >
              <SideNavComponent />
            </Col> 
            <Col>
              <Container fluid  style={{paddingLeft:'0', paddingRight:'0', marginTop:'2rem'}}>
                {context.fetching &&  sezioni.fetching && faqs.fetching && <Loader isLoading={context.fetching || sezioni.fetching || faqs.fetching } /> }
                {context.fetched && sezioni.fetched  && contatori.fetched && <FaqListComponent {...props} context={context.data} sezioni={sezioni.data} faqs={faqs.data} sottosezioni={sottosezioni.data} stato={this.state} setSezione={this.toggleSezione} setPage={this.togglePage} contatore={contatori.data} />}
                {context.errors && context.errors.length > 0 && <p>{context.errors}</p>}
                {sezioni.errors && sezioni.errors.length > 0 && <p>{sezioni.errors}</p>}
              </Container>  
            </Col>
          </Row>
    );
  }
}

FaqListContainer.propTypes = {};

FaqListContainer.defaultProps = {};

const mapStateToProps = ({ context, sezioni, faqs, contatori, sottosezioni }) => {
  return {
    context,
    sezioni: addSezioni(sezioni),
    faqs,
    contatori,
    sottosezioni
  }
};

export default connect(mapStateToProps, { fetchStart, getSezioni, getSottosezioni, getFaq, countFaqs })(withRouter(FaqListContainer));