import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import withSizes from "react-sizes";
import { Col, Container, Row } from "reactstrap";
import { MOBILE_SIZE } from "../../../constants";
import { SCIMMODIFICA } from "../../../navigation/routes";
import styles from "./ScimUtente.module.css";
import { withRouter } from "react-router-dom";
import Button from "reactstrap/lib/Button";

const ScimUtente = ({ userInfo, isMobile }) => {
  const dataFmt = (date) => {
    if (date === "NO") {
      return "Data Creazione non presente";
    } else {
      return moment(date).format("DD/MM/YYYY HH:mm");
    }
  };

  const statoFormatter = (userInfo) => {
    console.log("userInfo");
    console.log(userInfo);
    let status;
    //attivo
    if (userInfo.active) {
      status = { icon: "user-check", classname: "text-success" };
      return (
        <span>
          <FontAwesomeIcon icon={status.icon} className={status.classname} />
        </span>
      );
    } else {
      //non attivo
      status = { icon: "user-times", classname: "text-danger" };
      return (
        <span>
          <FontAwesomeIcon icon={status.icon} className={status.classname} />
        </span>
      );
    }
  };

  const borderFormatter = (userInfo) => {
    let status;

    if (userInfo.active) {
      status = styles.bordered_success;
    } else {
      status = styles.bordered_danger;
    }

    return status;
  };

  return (
    <div
      id="ticket-container"
      className={`${styles.containerkit} ${
        isMobile ? borderFormatter(userInfo) : ""
      }`}
      // onClick={() => {
      //   goto({ pathname: SCIMMODIFICA, state: userInfo });
      // }}
    >
      <Container fluid>
        <Row className={`${styles.ticket_data} justify-content-end`}>
          <Col>
            <b>Data di creazione:</b>&nbsp;{userInfo.created_date}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.ticket_title}>
              <b>Username:</b>&nbsp;
              <span className="text-info">
                <b>{userInfo.user_name}</b>
              </span>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "8px" }}>
          <Col xs={{ size: !isMobile ? 8 : 12 }}>
            <div id="ticket-corpus" className={styles.ticket_from}>
              <span className={`labels-title ${styles.ticket_labels}`}>
                Nome:
              </span>
              &nbsp;{userInfo.display_name}
            </div>
          </Col>
          {/* {!isMobile && (
            <Col
              xs={{ size: 2, offset: 2 }}
              className={`${styles.ticket_icon}`}
            >
              {statoFormatter(userInfo)}
            </Col>
          )} */}
        </Row>
        <Row style={{ marginTop: "8px" }}>
          <Col xs="12" className={styles.ticket_from}>
            <span className={`labels-title ${styles.ticket_labels}`}>
              Email:
            </span>
            &nbsp;
            <span>
              {userInfo.email === undefined ? "Non Presente" : userInfo.email}
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "8px" }}>
          <Col xs="12" className={styles.ticket_from}>
            <span className={`labels-title ${styles.ticket_labels}`}>
              Code:
            </span>
            &nbsp;
            <span>
              {userInfo.code === undefined ? "Non Presente" : userInfo.code}
            </span>
          </Col>
        </Row>
        &nbsp;
        <Row>
          <Col xs="12" className={styles.ticket_from}>
            <span>
              <Button
                variant="primary"
                href={"https://ricerca2021.cbim.it/?token=" + userInfo.token}
                target="_blank"
              >
                Workflow
              </Button>
            </span>
            &nbsp;
            <span>
              <Button
                variant="primary"
                href={"https://pnrr.cbim.it/?token=" + userInfo.token}
                target="_blank"
              >
                PNRR
              </Button>
            </span>
            &nbsp;
            <span>
              <Button
                variant="primary"
                href={
                  "https://finreport-fe.cbim.it/loginsso?token=" +
                  userInfo.token
                }
                target="_blank"
              >
                Finreport
              </Button>
            </span>
            &nbsp;
            <span>
              <Button
                variant="primary"
                href={
                  "https://questionari.cbim.it/?token=" +
                  userInfo.token
                }
                target="_blank"
              >
                Questionari
              </Button>
            </span>
            &nbsp;
            <span>
              <Button
                variant="primary"
                href={
                  "https://ricerca-doc.cbim.it/auth/" +
                  userInfo.token
                }
                target="_blank"
              >
                Documentazione
              </Button>
            </span>
            &nbsp;
            <span>
              <Button
                variant="primary"
                href={"https://prev22.cbim.it/?token=" + userInfo.token}
                target="_blank"
              >
                Prevenzione
              </Button>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ScimUtente.propTypes = {
  userInfo: PropTypes.object,
  // goto: PropTypes.func,
};

ScimUtente.defaultProps = {
  userInfo: {},
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE,
});

export default withSizes(mapSizesToProps)(withRouter(ScimUtente));
