import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';

import { AuthContext } from '../authentication';
import {LOGIN, UNAUTHORIZED} from './routes';

const AuthRoute = ({component: Component, claim, ...rest}) => {
const {isAuth, role} = useContext(AuthContext);

  // tutto ok
  if (isAuth && claim.includes(role))
    return (
      <Route 
        render={props => <Component {...props} />} 
        {...rest}
      /> 
    );

  // autenticazione da fare
  if (!isAuth)
  return (
    <Route 
      render={props => <Redirect to={{pathname:LOGIN, state: {from: props.location}}} />} 
      {...rest}
    /> 
  );

  // autenticato ma livello insufficiente
  return (
    <Route 
      render={props => isAuth && claim.includes(role) ? 
        <Component {...props} /> : 
        <Redirect to={{pathname: UNAUTHORIZED}} />} 
      {...rest}
    /> 
  );
};

AuthRoute.propTypes = {};

AuthRoute.defaultProps = {};

export default AuthRoute;