import React from 'react';
import { Alert } from 'reactstrap';
import {LDAP_ADMIN, LDAP_APP, LDAP_MOH, LDAP_SMS} from '../constants'



//Aggiungi la sezione vuota

export const addSezione = arrSezioni => [{idSezione:99, nome:"Seleziona"}, ...arrSezioni];

export const addSottosezione = (arrSottosezione, idSezione) => [{id: 99, idSezione, desc:"Seleziona"}, ...arrSottosezione];

export const renameObjectArrayProps = (objArr, pId, pDesc) => objArr.map(obj => ({ ...obj, id: obj[pId], desc: obj[pDesc] }));

//controllo dell'errore 
export const showErrorMsg = (risposta) => {
    if(risposta.response.status) 
        return (<Alert color="danger"> {risposta.messaggio + risposta.response.data.error } </Alert>);  
    else
        return null;
}

export const filterUtente = (utenti, pos) => {
    return utenti.filter(function (utente) { return utente.idUtente === pos })[0].nickname;
}

export const checkRole = (userRole, grants) => {
    return grants.includes(userRole);
}

export const groups = [ {id: 99, desc:"Seleziona"},
                        {id: LDAP_ADMIN, desc:"Admin"},
                        {id: LDAP_MOH, desc:"Ministero"},
                        {id: LDAP_APP, desc:"App"},
                        {id: LDAP_SMS, desc:"SMS"} ];

export const trimma = (obj) => {
    return Object.keys(obj).reduce((acc, curr) => {
        acc[curr] = obj[curr].trim()
        return acc;
    }, {});
}                        