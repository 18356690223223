import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from  'react-router-dom';

import { Container, Row, Col, Button} from 'reactstrap';
import {PUBLICFAQ} from '../../navigation/routes';
import GlobalSearch from './GlobalSearch';
import styles from './Faq.module.css';


const FaqComponent = ({sezioni, sottosezioni, printFaq}) => {

  const [arrayNav, setArrayNav] = useState(["Home"]);

  const showSubsection = (idSezione, subsections) => {
    return subsections.map( subsection => {
      return( 
        <Row key={subsection.idSottoSezione} className={styles.row_content}>
          <Link to={`${PUBLICFAQ}/${idSezione}/${subsection.idSottoSezione}`}><Button color="link">{subsection.descrizione}</Button></Link>
        </Row>
      )
    })
  };

  const showSection = (sezioni, sottosezioni) => {
    return sezioni.map( section => {
      let subSectionFiltered = sottosezioni.filter(e => e.idSezione === parseInt(section.idSezione));
      return(
          <Col sm="4" key={section.idSezione} >
            <Row className={styles.row_content}>
              <Link to={`${PUBLICFAQ}/${section.idSezione}/all`}>
                <Button onClick={() => {setArrayNav([...arrayNav, section.nome])}} color="secondary"><b>{section.nome}</b></Button>
              </Link>
            </Row>
            {showSubsection(section.idSezione,subSectionFiltered)}
          </Col>
      )
    })
  };


  return(
    <Container className={styles.container}>
      <Row>
      <div className={styles.title}>
        <h3>Sezione FAQ Pubbliche</h3>
        <div className={styles.subtitle}>
          <div>
            Effettua una <strong>ricerca globale</strong> tra le FAQ pubblicate o  
            <strong> naviga tra le varie sezioni disponibili</strong> con il menù sottostante.
          </div>
        </div>
      </div>
      </Row>
      <Row>
        <GlobalSearch printFaq={printFaq}/>
      </Row>
      <Row className={styles.row_title}>
        <h3>FAQ - Domande Frequenti</h3>
      </Row>
      <hr />
      <Row className={styles.navigation_menu}>
        {showSection(sezioni, sottosezioni)}
      </Row>
    </Container>

  )
}

FaqComponent.propTypes = {
  sezioni: PropTypes.array,
  sottosezioni: PropTypes.array,
};

FaqComponent.defaultProps = {};

export default FaqComponent;