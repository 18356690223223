import { faSearch, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Col, Form, Row } from "reactstrap";
import { SCIMMANGER } from "../../navigation/routes";
import { RowInput } from "../form";
import styles from "./ScimGestioneUtenti.module.css";
import ListaUtenti from "./ScimList";

const ScimGestioneUtentiComponent = ({
  values,
  touched,
  errors,
  e,
  status,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  setFieldError,
  isSubmitting,
  isValid,
  groupList,
}) => (
  <>
    <Row>
      <Col>
        <div className={styles.mainContainer}>
          <h4 className={styles.title}>Cerca Utenti</h4>
          Cerca un utente tramite username
        </div>
      </Col>
      <Col>
        <div className={styles.buttonContainer}>
          <Link to={SCIMMANGER}>
            <Button color="warning" size="sm">
              <FontAwesomeIcon icon={faArrowLeft} />
              <span className="smallLabel">
                &nbsp;Annulla<small></small>
              </span>
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
    <hr />

    <Form onSubmit={handleSubmit}>
      <div>
        <div className={styles.content}>
          <Field
            type="text"
            label="Codice Fiscale"
            name="username"
            component={RowInput}
          />
          <Field type="text" label="Phone" name="phone" component={RowInput} />
          <Field type="text" label="Email" name="email" component={RowInput} />
        </div>
        <div className={styles.submit}>
          <Button
            color="success"
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            <FontAwesomeIcon icon={faSearch} />
            &nbsp;Cerca<small></small>
          </Button>
        </div>
      </div>
    </Form>
    {status && !status.success && (
      <>
        <br />
        <Alert color="danger">{status.errore} </Alert>
      </>
    )}
    {status && status.success && <ListaUtenti users={status.users} />}
  </>
);

ScimGestioneUtentiComponent.propTypes = {
  uuid_ldap: PropTypes.string,
  setUuid_ldap: PropTypes.func,
  performSearch: PropTypes.func,
};

ScimGestioneUtentiComponent.defaultProps = {};

export default ScimGestioneUtentiComponent;
