import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { Button, Collapse, Navbar, NavbarBrand, NavbarToggler,  Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu ,DropdownItem } from 'reactstrap';
import { HOME, FAQ, NONEVASI, LOGIN, FAQMANAGER, EVASI, SCIMMANGER, ALL, MOH, FAQPUBBLICATE, FAQBOZZA, FAQELIMINATE, NEWSMANAGER, MAILMANAGER } from '../../navigation/routes';
import logo from '../../assets/logo_circular.png';
import styles from  './Navbar.module.css';
import withSizes from 'react-sizes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MOBILE_SIZE, TYPEFAQ, TYPEMESSAGE, NEWS } from '../../constants';
import SearchBar from '../SearchBar';
import { AuthContext } from '../../authentication';
import { ADMIN, CBIM } from '../../constants';
import { checkRole } from '../../helpers';

const mapSizesToProps = ({ width }) => ({
    isMobile: width <= MOBILE_SIZE,
  })

const findSection = (path) => {
    let section;
    if(path.includes(TYPEFAQ))
        section = TYPEFAQ;
    if(path.includes(TYPEMESSAGE))
        section = TYPEMESSAGE;
    if(path.includes(NEWS))
        section = NEWS;
    return section;
}

const NavbarComponent = ({isAuth, doLogout, history, location: { pathname }, isMobile}) => {
    
    const [isOpen, toggle] = useState(false);
    const { utente } = useContext(AuthContext);

    
    const onLogout = () => {
        doLogout();
        history.push(LOGIN);
    }

    return (
        <Navbar color="primary" dark expand="md">
            <NavbarBrand to={HOME}>
                <img src={logo} className={styles.logo} alt="CBIM" width="48" height="48" />
            </NavbarBrand>
            <NavbarToggler onClick={() => toggle(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavItem onClick={() => toggle(false)}>
                        <NavLink active={pathname === HOME} tag={Link} to={HOME}>Richiedi Supporto Tecnico</NavLink>
                    </NavItem>
                    <NavItem onClick={() => toggle(false)}>
                        <NavLink active={pathname === FAQ} tag={Link} to={FAQ}>Consulta FAQ</NavLink>
                    </NavItem>
                </Nav>
                <Nav className="ml-auto" navbar>
                    <NavItem onClick={() => toggle(false)}>
                        <NavLink active={pathname === NONEVASI} tag={Link} to={NONEVASI}>Amministrazione</NavLink>
                    </NavItem>
                    <NavItem>
                    {isAuth && isMobile && 
                         <UncontrolledDropdown>
                         <DropdownToggle caret color="primary">
                           Sezioni
                         </DropdownToggle>
                         <DropdownMenu>
                           <DropdownItem ><Link to={NONEVASI} style={{color:'#333'}}><FontAwesomeIcon icon="envelope" />&nbsp;Ticket non evasi</Link></DropdownItem>
                           <DropdownItem ><Link to={EVASI} style={{color:'#333'}}><FontAwesomeIcon icon="envelope-open" />&nbsp;Ticket evasi</Link></DropdownItem>
                           <DropdownItem ><Link to={ALL} style={{color:'#333'}}><FontAwesomeIcon icon="inbox" />&nbsp;Tutti i ticket CBIM</Link></DropdownItem>
                           {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <DropdownItem ><Link to={MOH} style={{color:'#333'}}><FontAwesomeIcon icon="university" />&nbsp;Ticket MOH</Link></DropdownItem>}
                           <DropdownItem ><Link to={FAQMANAGER} style={{color:'#333'}}><FontAwesomeIcon icon="question-circle" />&nbsp;FAQ Manager</Link></DropdownItem>
                           <DropdownItem ><Link to={FAQBOZZA} style={{color:'#333'}}><FontAwesomeIcon icon="file-alt"/>&nbsp;FAQ in bozza</Link></DropdownItem>
                           <DropdownItem ><Link to={FAQPUBBLICATE} style={{color:'#333'}}><FontAwesomeIcon icon="stamp" />&nbsp;FAQ pubblicate</Link></DropdownItem>
                           <DropdownItem ><Link to={FAQELIMINATE} style={{color:'#333'}}><FontAwesomeIcon icon="trash" />&nbsp;FAQ eliminate</Link></DropdownItem>
                           {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <DropdownItem ><Link to={NEWSMANAGER} style={{color:'#333'}}><FontAwesomeIcon icon="newspaper" />&nbsp;NEWS Manager</Link></DropdownItem>}
                           {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <DropdownItem ><Link to={SCIMMANGER} style={{color:'#333'}}><FontAwesomeIcon icon="server" />&nbsp;LDAP Manager</Link></DropdownItem>}
                           {checkRole(utente.idRuolo, [ADMIN, CBIM]) && <DropdownItem ><Link to={MAILMANAGER} style={{color:'#333'}}><FontAwesomeIcon icon="at" />&nbsp;Mail Manager</Link></DropdownItem>}
                         </DropdownMenu>
                       </UncontrolledDropdown>
                    }
                    </NavItem>
                    <NavItem>
                        {isAuth && <Button color="primary" onClick={onLogout}>Logout</Button>}
                    </NavItem>
                    {isAuth && 
                        <NavItem>
                            <SearchBar section={findSection(pathname)} />
                        </NavItem>
                    }
                </Nav>
            </Collapse>
        </Navbar>
    );
}

NavbarComponent.propTypes = {
    isAuth: PropTypes.bool,
    doLogout: PropTypes.func.isRequired
};

NavbarComponent.defaultProps = {
    isAuth: false
};


export default withRouter(withSizes(mapSizesToProps)(NavbarComponent));