import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import api from '../../../api';
import { AuthContext } from '../../../authentication';
import { errors } from '../../form';
import AddFaqComponent from './AddFaqComponent';
import { FAQDETAIL } from '../../../navigation/routes';
const { OB } = errors;

// schema di validazione
const formSchema = Yup.object().shape({
  titolo: Yup.string().required(OB),
  domanda: Yup.string().required(OB),
  risposta: Yup.string().required(OB),
});

const AddFaqContainer = ({idSezione, idSottosezione, messaggio, history}) => {

  const {utente} = useContext(AuthContext);
  const goto = location => history.push(location);
  const [error, setError] = useState();



  //valori iniziali del form
  const initialValues = {
    titolo: '',
    domanda: messaggio.messaggio === undefined ? '' : messaggio.messaggio,
    risposta: messaggio.risposte !== undefined && messaggio.risposte.length > 0 ? messaggio.risposte[0].testo : '' ,
  };


  return (
    <Formik
    initialValues={initialValues}
    validationSchema={formSchema}
    onSubmit={ async (values, actions) => {
        values={...values,
          idMessaggio: messaggio.messaggio === undefined ? null : messaggio.idMessaggio,
          idSezione: idSezione,
          idSottosezione: idSottosezione,
          idUtente: utente.idUtente
        }
        try {
          let response = await api.setFaq(values);
          actions.setSubmitting(false);
          actions.resetForm();
          goto({pathname: FAQDETAIL, state: response.data })
        } catch (er) {
          setError(
          { ...er,
            messaggio: 'Faq non creata correttamente. Tipo di errore: '
          });
          actions.setSubmitting(false);
        }
    }}
    render={props => <AddFaqComponent {...props} errore={error}  /> }
  >
  </Formik>

    )
};

AddFaqContainer.propTypes = {
  idSezione: PropTypes.number,
  idSottosezione: PropTypes.number,
  messaggio: PropTypes.object,
};

AddFaqContainer.defaultProps = {
  messaggio: {},
};

export default withRouter(AddFaqContainer);