import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Badge } from 'reactstrap';
import styles from  './ContentElement.module.css';
import Truncate from 'react-truncate';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withSizes from 'react-sizes';
import { MESSAGGIO, SEND , FAQBOZZA} from '../../navigation/routes';
import { MOBILE_SIZE } from '../../constants';
import { filterUtente } from '../../helpers';

const ContentElementComponent = ({messaggio, argomento, utenti, isMobile, goto}) => {

    const dataFmt = (date) => {
        return moment(date).format('DD/MM/YYYY HH:mm');
    } 

    const statoFormatter = (row, utenti) => {
        let status;
        let prsCharge = row.flagPresaincarico !== 0;
        //cancellato
        if (row.cancellato === 1 ) {
          status = {icon: 'trash', classname: 'text-danger' };
          return(<span><FontAwesomeIcon icon={status.icon} className={status.classname}/></span>);
        } else {
          //evaso
          if (row.risposte && row.risposte.length > 0)
            status = {icon: 'check-circle', classname: 'text-success' };
          //non evaso
          if (row.risposte && row.risposte.length === 0)
            status = {icon: 'exclamation-triangle', classname: 'text-warning' };
          
        
          let returnable = !prsCharge ?  
                          (<div style={{textAlign:'center'}}><span ><FontAwesomeIcon size="lg" icon={status.icon} className={status.classname}/></span></div>) : 
                          (<div style={{textAlign:'center'}}><span><FontAwesomeIcon size="lg" icon="flag" className="text-danger"/>
                            <br/>
                            <Badge color="danger">{filterUtente(utenti, row.flagPresaincarico)}</Badge></span>
                          </div>)
          return ( returnable);
        }
      }

    const dispatchMsg = (goto, msg) => {
      if(msg.risposte && msg.risposte.length === 0)
        goto(`${MESSAGGIO}/${messaggio.idMessaggio}`);
      else
        goto(`${SEND}/${messaggio.idMessaggio}`);
    }

    const borderFormatter= (messaggio, styles) => {     
        
        let status = styles.bordered_warning;

               
        if(messaggio.risposte && messaggio.risposte.length > 0)
          status = styles.bordered_success;
        
        if (messaggio.risposte && messaggio.risposte.length === 0)
        status = styles.bordered_warning;

        if (messaggio.cancellato === 1 ) 
          status = styles.bordered_danger;

        return(status);

    }
    return(
          
            <div id="ticket-container" className={`${styles.containerkit} ${isMobile ?  borderFormatter(messaggio, styles): ''}`} 
                 onClick={() => { messaggio.flagBozzaFaq === 0 || messaggio.risposte.length > 0  ? dispatchMsg(goto, messaggio) : goto(FAQBOZZA) }}    >
                <Container fluid>
                    <Row className={`${styles.ticket_data} justify-content-end`}>
                    <Col>
                        Ricevuto: {dataFmt(messaggio.dataRicezione)}<br/>
                        {messaggio.risposte.length > 0 && <>Evaso: {dataFmt(messaggio.risposte[0].dataEvasione)}</>}
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className={styles.ticket_title}>
                            #&nbsp;<span className="text-info"><b>{messaggio.idMessaggio}</b></span>
                            <span className={styles.ticket_dash}>&nbsp;&mdash;&nbsp;</span>
                            {argomento.descrizione.toUpperCase()}
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: !isMobile ? 8 : 12}} >
                            <div id="ticket-corpus" className={styles.ticket_corpus}>
                                <Truncate lines={2} ellipsis={<span>...</span>}> {messaggio.messaggio} </Truncate>
                            </div>
                        </Col>
                        {!isMobile && <Col xs={{size:2,  offset:2}} className={`${styles.ticket_icon}`} >
                            {statoFormatter(messaggio, utenti)}
                        </Col>
                        }
                    </Row>
                    
                    <Row style={{marginTop:'16px'}}>
                      <Col xs="12" className={styles.ticket_from}>
                        <span className={`labels-title ${styles.ticket_labels}`}>Mittente:</span>&nbsp;{isMobile ? (<br/>):''}
                        <span><small><Truncate lines={1} ellipsis={<span>...</span>}> {`${messaggio.email}`}</Truncate></small></span>
                      </Col>
                    </Row>
                    <Row>
                      {messaggio.flagBozzaFaq !== 0 && messaggio.risposte.length === 0 &&
                        <Col xs={{size:6}} >
                          <Badge color="danger">Risposta creata tramite la funzione Bozza FAQ - per rispondere pubblica la FAQ #{messaggio.flagBozzaFaq} o cancellala per sbloccare il messagggio</Badge>
                        </Col>
                      }
                      {messaggio.flagPresaincarico !== 0 && isMobile && <Col xs={{size:3, offset:messaggio.flagMinistero === 1?6:9}} className="justify-content-end"><Badge color="danger">{filterUtente(utenti, messaggio.flagPresaincarico)}</Badge></Col>}
                      {messaggio.flagMinistero === 1 && <Col xs={{size:2, offset:messaggio.flagBozzaFaq !== 0 ? 4 : 10}} ><Badge color="warning">MOH</Badge></Col>}

                    </Row>
                </Container>
            </div>
    );

}

ContentElementComponent.propTypes = {
    messaggio: PropTypes.object,
    argomento: PropTypes.object,
    goto: PropTypes.func
};
  
ContentElementComponent.defaultProps = {
  messaggio: {},
  argomento: {}
};
  

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE,
})
  

export default withSizes(mapSizesToProps)(ContentElementComponent);