import React , { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import { FAQDETAIL } from '../../../navigation/routes';
import SideNavComponent from '../../Sidenav/SideNavComponent';
import ChangeFaqComponent from './ChangeFaqComponent';
import api from '../../../api';
import { renameObjectArrayProps } from '../../../helpers';
import { errors } from '../../form';
const { OB } = errors;

// schema di validazione
const formSchema = Yup.object().shape({
    titolo: Yup.string().required(OB),
    domanda: Yup.string().required(OB),
    risposta: Yup.string().required(OB),
    idSezione: Yup.string()
    .test(
      'section', 'Seleziona una sezione',
      val => val !== '99'
    ),
    idSottosezione: Yup.string()
    .test(
        'section', 'Seleziona un argomento',
        val => val !== '99'
    ),
  });


const ChangeFaqContainer = ({ utente, location, history, sezioni, sottosezioni}) => {

    const [error, setError] = useState();

    const faq = location.state;

    const goto = location => history.push(location);

    //valori iniziali del form
    const initialValues = {...faq};
    

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        isInitialValid = {true}
        onSubmit={ async (values, actions) => {
            try {
              if(values.idSezione !== faq.idSezione && values.idSottosezione === faq.idSottosezione)
                values.idSottosezione = sottosezioni.filter(e => e.idSezione === parseInt(values.idSezione))[0].idSottoSezione
              const response = await api.setFaq(values);
              actions.setSubmitting(false);
              // actions.resetForm();
              //actions.setStatus({
                 //inviato: true
              //});
              goto({pathname:FAQDETAIL, state:response.data})
            } catch (er) {
              setError(
              { ...er,
                messaggio: 'Faq non modificata correttamente. Tipo di errore: '
              });
              actions.setSubmitting(false);
            }
        }}
        render={props => 
            <Row style={{margin:'0'}}>
                <Col xs="1" className="hidden-xs-down sidebar" >
                    <SideNavComponent />
                </Col> 
                <Col style={{overflowX:'auto'}}>
                    <Container fluid >
                        <ChangeFaqComponent {...props} errore={error} sezioni={renameObjectArrayProps(sezioni,"idSezione","nome")} sottosezioni={renameObjectArrayProps(sottosezioni,"idSottoSezione","descrizione")} faq={faq}/> 
                    </Container>
                </Col>
            </Row>
        }
      >
      </Formik>

    )
}

ChangeFaqContainer.propTypes = {};
  
ChangeFaqContainer.defaultProps = {};

const mapStateToProps = ({ context, sezioni, sottosezioni }) => {
    return {
      utente: context.data[0],
      sezioni: sezioni.data,
      sottosezioni: sottosezioni.data
    }
};

export default connect(mapStateToProps)(withRouter(ChangeFaqContainer));