import api from '../api';

const API = 'public_faq';
const PENDING = `${API}_PENDING`;
const FULFILLED = `${API}_FULFILLED`;
const REJECTED = `${API}_REJECTED`;

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  errors: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PENDING:
      return {...state, fetching: true, fetched: false};

    case REJECTED:
      return {...initialState, errors: payload};

    case FULFILLED:
      return { data: [...payload.data.content], fetching: false, fetched: true};
    default: return state;
  }
}

export const getPublicFaq = (idSezione, idSottosezione, page) => ({
  type: API,
  payload: api.getFaqPubblicheFiltrate(idSezione, idSottosezione, page)
});
