import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { errors } from '../../form';
import AddNewsComponent from './AddNewsComponent';

const { OB } = errors;


const initialValues = {
    titolo: '',
    contenuto: '',
    colore: '#0275d8'
  };

const formSchema = Yup.object().shape({
    titolo: Yup.string().required(OB),
    contenuto: Yup.string().required(OB),
    colore: Yup.string().required(OB),
});

const AddNewsContainer = (props) => {
    
    const [error, setError] = useState();
    
    return(
        <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={ async (values, actions) => {
            values={...values,
            idUtente:props.utente.idUtente,
            cancellato: false,
            pubblicato: false
            }
            try {
                await props.api(values);
                actions.setSubmitting(false);
                actions.resetForm();
                props.controlUpdate(values)
                props.toggler(false);
            } catch (er) {
            setError(
            { ...er,
                messaggio: 'News non creata correttamente. Tipo di errore: '
            });
            actions.setSubmitting(false);
            }
        }}
            render={props => <AddNewsComponent {...props} errore={error}  /> }
        >
        </Formik>
    );
} 

AddNewsContainer.propTypes = {
    utente: PropTypes.object,
    api: PropTypes.func,
    controlUpdate: PropTypes.func,
    toggler: PropTypes.func
};

AddNewsContainer.defaultProps = {
    utente: {}
};

export default AddNewsContainer;