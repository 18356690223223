import { Formik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../../api';
import { errors } from '../../form';
import SideNavComponent from '../../Sidenav/SideNavComponent';
import MailNuovoTemplateComponent from './MailNuovoTemplateComponent';

const { OB } = errors;

// valori iniziali del form
const initialValues = {
  nomeTemplate:'',
  params: ['']
};

// schema di validazione
const formSchema = Yup.object().shape({
  nomeTemplate: Yup.string().required(OB),
  params: Yup.array().of(
    Yup.string().required()
  ).required()
});

const MailNuovoTemplateContainer = () =>{ 

  return (    
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={ async (values, actions)  => {

          try{
            let response = await api.createTemplate(values)
            actions.setStatus({
              template: response.data,
              success: true,
            })  
            actions.setSubmitting(false)
          } catch (er) {
            actions.setStatus({
              success: false,
              errore: "Applicativo non creato correttamente"
            })  
            actions.setSubmitting(false)
          }
          
        }
      }
      render={props => 
        <Row style={{margin:'0'}}>
          <Col xs="1" className="hidden-xs-down sidebar" >
            <SideNavComponent />
          </Col> 
          <Col style={{overflowX:'auto'}}>
            <Container fluid  >
              <MailNuovoTemplateComponent {...props} /> 
            </Container>
          </Col>
        </Row> 
      }
    >
    </Formik>
  )
};

MailNuovoTemplateContainer.propTypes = {};

MailNuovoTemplateContainer.defaultProps = {};

export default MailNuovoTemplateContainer;