import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ADMIN, CBIM, MR_G, MOH_RUOLO} from '../constants';
import * as routes from './routes';
import AuthRoute from './AuthRoute';
import Home from '../components/Home';
import Faq from '../components/Faq';
import LoginForm from '../components/LoginForm';
import Admin from '../components/Admin';
import Unauthorized from '../components/Unauthorized';
import Messaggio from '../components/Messaggio';
import Evaso from '../components/Messaggio/Evaso';
import FaqManager from '../components/FaqManager';
import FaqDetail from '../components/FaqManager/FaqDetail';
import ChangeFaq from '../components/FaqManager/ChangeFaq';
import FaqList from '../components/FaqManager/FaqList';
import NewsContainer from '../components/News';
import SearchOutput from '../components/SearchOutput';
import FaqPublicList from '../components/Faq/FaqPublicList';
import NewsContentElementContainer from '../components/News/NewsContentElement';
import ScimManagerContainer from '../components/ScimManager';
import ScimRegistrazione from '../components/ScimRegistrazione';
import ScimGestioneUtenti from '../components/ScimGestioneUtenti';
import ScimModifica from '../components/ScimGestioneUtenti/ScimModifica';
import MailManager from '../components/MailManager';
import MailNuovoApplicativo from '../components/MailManager/MailNuovoApplicativo';
import MailNuovoTemplate from '../components/MailManager/MailNuovoTemplate';
import FaqPrintList from '../components/FaqPrintList/'

export const AppRoutes = () =>{


  return(
    <Switch>
      <Route exact path={routes.HOME} component={() => <Home />} />
      <Route exact path={routes.FAQ} component={() => <Faq />} />
      <Route exact path={`${routes.PUBLICFAQ}/:idSezione/:idSottosezione`} component={() => <FaqPublicList />} />
      <Route exact path={routes.PUBLICFAQ} component={() => <FaqPublicList />} />
      <Route exact path={routes.PRINT_FAQ_LIST} component={() => <FaqPrintList />} />
      <AuthRoute exact path={routes.NONEVASI} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <Admin />} />
      <AuthRoute exact path={routes.EVASI} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <Admin />} />
      <AuthRoute exact path={routes.ALL} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <Admin />} />
      <AuthRoute exact path={routes.MOH} claim={[ADMIN, CBIM]} component={() => <Admin />} />
      <AuthRoute exact path={`${routes.MESSAGGIO}/:id`} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <Messaggio />} />
      <AuthRoute exact path={`${routes.SEND}/:id`} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <Evaso />} />
      <AuthRoute exact path={routes.FAQMANAGER} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <FaqManager />} />
      <AuthRoute exact path={routes.FAQDETAIL} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <FaqDetail />} />
      <AuthRoute exact path={routes.CHANGEFAQ} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <ChangeFaq />} />
      <AuthRoute exact path={routes.FAQPUBBLICATE} claim={[ ADMIN,  CBIM, MR_G, MOH_RUOLO ]} component={() => <FaqList />} />
      <AuthRoute exact path={routes.FAQBOZZA} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <FaqList />} />
      <AuthRoute exact path={routes.FAQELIMINATE} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <FaqList />} />
      <AuthRoute exact path={routes.SEARCHMESSAGE} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <SearchOutput />} />
      <AuthRoute exact path={routes.SEARCHFAQ} claim={[ADMIN, CBIM, MR_G, MOH_RUOLO]} component={() => <SearchOutput />} />
      <AuthRoute exact path={routes.NEWSMANAGER} claim={[ADMIN, CBIM]} component={() => <NewsContainer />} />
      <AuthRoute exact path={`${routes.NEWSDETAIL}/:id`} claim={[ADMIN, CBIM]} component={() => <NewsContentElementContainer />} />
      <AuthRoute exact path={routes.SCIMMANGER} claim={[ADMIN, CBIM]} component={() => <ScimManagerContainer />} />
      <AuthRoute exact path={routes.SCIMREGISTRAZIONE} claim={[ADMIN, CBIM]} component={() => <ScimRegistrazione />} />
      <AuthRoute exact path={routes.SCIMCERCA} claim={[ADMIN, CBIM]} component={() => <ScimGestioneUtenti />} />
      <AuthRoute exact path={routes.SCIMMODIFICA} claim={[ADMIN, CBIM]} component={() => <ScimModifica />} />
      <AuthRoute exact path={routes.MAILMANAGER} claim={[ADMIN, CBIM]} component={() => <MailManager />} />
      <AuthRoute exact path={routes.MAIL_CREA_APPLICATIVO} claim={[ADMIN, CBIM]} component={() => <MailNuovoApplicativo />} />
      <AuthRoute exact path={routes.MAIL_CREA_TEMPLATE} claim={[ADMIN, CBIM]} component={() => <MailNuovoTemplate />} />
      <Route exact path={routes.LOGIN} component={() => <LoginForm />} />
      <Route exact path={routes.UNAUTHORIZED} component={() => <Unauthorized />} />
    </Switch>
  );
}

  

export default AppRoutes;
