import React from 'react';
import { Button, Card, CardImg, Col, Container, Row, ButtonGroup } from 'reactstrap';
import crea from '../../assets/crea_mail.jpg';
// import modifica from '../../assets/edit_mail.png';
import costruzione from '../../assets/pagina_costruzione.jpg';
import SideNavComponent from '../Sidenav/SideNavComponent';
import styles from './MailManager.module.css';
import { Link } from 'react-router-dom';
import { MAIL_CREA_APPLICATIVO, MAIL_CREA_TEMPLATE } from '../../navigation/routes';


const MailManager = () =>{

    return(
        <Row style={{margin:'0'}}>
            <Col xs="1" className="hidden-xs-down sidebar" >
                <SideNavComponent />
            </Col> 
            <Col style={{overflowX:'auto'}}>
            <div className={styles.mainContainer}>
                <h4 className={styles.title}>Gestione Mail Service</h4>
                <h6>Pannello di controllo per il servizio di mail CBIM</h6>
                <hr />
            </div>
                <Container fluid>
                    <Row style={{margin:'0'}}>
                            <Col sm={{ size: 4, offset: 1 }} >
                                <Card body >
                                    <CardImg top width="80%"  src={crea} alt="Crea nuovo utente" />
                                    <br/>
                                    <ButtonGroup>
                                        &nbsp;&nbsp;&nbsp;
                                        <Link to={MAIL_CREA_APPLICATIVO}><Button>Crea nuovo applicativo</Button></Link> &nbsp;&nbsp;&nbsp;
                                        <Link to={MAIL_CREA_TEMPLATE}><Button>Crea nuovo template</Button></Link>
                                    </ButtonGroup>
                                </Card>
                            </Col>
                            <Col sm={{ size: 4, offset: 2 }} >
                                <Card body >
                                    <CardImg top width="80%" src={costruzione} alt="Crea nuovo utente" />
                                    <br/>
                                    <ButtonGroup>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button>Modifica applicativo</Button> &nbsp;&nbsp;&nbsp;
                                        <Button>Modifica template</Button>
                                    </ButtonGroup>
                                </Card>
                            </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
       
    );
}


export default MailManager;